<template>
  <div>
    <b-navbar toggleable="xl" class="navbar" type="dark">
      <b-navbar-brand :class="{ 'extended-nav': showPublicus }">
        <router-link to="/"><img src="../../assets/assets-06.png" alt="" class="img"></router-link>
        <router-link to="/pia2022"><img src="../../assets/publicus-logo.png" alt="" class="publicus-img" v-if="showPublicus"></router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-up" class="icon"></b-icon>
          <b-icon v-else icon="chevron-bar-down" class="icon"></b-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <!-- <b-input-group id="form-group" class="mt-3 mb-3">
            <b-form-input size="sm" class="mr-5" placeholder="Search"></b-form-input>
            <b-button size="sm" class="ml-2 search" type="submit">Search</b-button>
          </b-input-group> -->
          <b-nav-item id="nav-item" @click="goToPres"><a class="nav-link" :class="{ active: isAllActive }">Presidential Election</a></b-nav-item>
          <b-nav-item id="nav-item" @click="goToVPres"><a class="nav-link" :class="{ active: isVPActive }">Vice Presidential Election</a></b-nav-item>
          <!-- <b-nav-item id="nav-item"><router-link to="/presidential-election/politics" class="nav-link disabled" :class="{ active: isPolActive }">Contact Us</router-link></b-nav-item> -->
          <!-- <b-nav-item id="nav-item"><router-link to="/presidential-election/art" class="nav-link disabled">Art</router-link></b-nav-item>
          <b-nav-item id="nav-item"><router-link to="/presidential-election/games" class="nav-link disabled">Games</router-link></b-nav-item>
          <b-nav-item id="nav-item"><router-link to="/presidential-election/celebrity" class="nav-link disabled">Celebrity</router-link></b-nav-item>
          <b-nav-item id="nav-item"><router-link to="/presidential-election/influencers" class="nav-link disabled">Influencers</router-link></b-nav-item> -->

          </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
    props: ["isAllActive", "isVPActive", "isSenActive", "isNewsActive", "showPublicus"],

    data: function() {
        return {
          userName: '',
        }
    },

    methods: {
        logOut() {
            this.$router.push('/')
        },

        goToPres() {
          this.$router.push({ name: 'Simulation', params: {userId:`${localStorage.getItem('userId')}` }})
        },

        goToVPres() {
          this.$router.push({ name: 'VSimulation', params: {userId:`${localStorage.getItem('userId')}` }})
        },

        getUserName() {
          this.userName = `${localStorage.getItem('userName')}`
        }
    },

    mounted() {
      this.getUserName();
    }
}
</script>

<style scoped>
  .navbar {
    padding:10px;
    margin-left:30px;
    background:none;
    color:white !important;
    text-decoration:none !important;
  }

  .navbar-brand {
    width:5%;
  }

  .extended-nav {
    width:22%;
  }

  .logoImg {
    width:100%;
  }

  .nav-link {
    font-size:15px;
    font-family:'PoppinsBold';

    padding:10px;
  }

  .active{
    background-color:orange;
    border-radius:5px;
  }

  .navbar-nav {
    margin-left:20px;
    padding:5px;
  }

  .nav-item {
    margin-left: 10px;
  }

  #dropdown-router{
    text-decoration: none;
    color:black;
  }

  .icon {
    color:white;
    border:none;
    font-size:30px;
  }

  .icon:hover {
    border:none;
  }

  .img {
    width:7rem;
  }

  #form-group {
    height:20px !important;
    border-radius: 10px;
    width:200px !important;
  }

  .search {
    border-color:green;
    background:transparent;
    color:green;
  }

  .title{
    color:white;
    margin:0;
    background-color: blue;
    padding:10px;
    width:200px !important;
    border-radius:5px;
  }

    @media screen and (max-width: 420px) {
        .navbar, .navbar-nav, .nav-item {
            padding: 10px;
            margin: 0;
        }

        .img {
          width:6rem;
        }

        .publicus-img {
          width:12rem;
        }
    }

    @media screen and (max-width: 320px) {
        .btn {
            width:100%;
            margin-top:20px;
        }

        .img {
          width:4rem;
        }

        .publicus-img {
          width:10rem;
        }
    }
</style>