<template>
        <div>
            <Header :isNewsActive="isNewsActive"></Header>
            <div class="body">
                <img src="../../../../assets/assets-01.jpg" alt="" class="bg">
                <h4><router-link style="text-decoration: none; font-weight:bold; color:white;" to="/news">← Go Back</router-link></h4>
                <div class="container-fluid news-body" id="news-one">
                    <div class="text-center">
                        <h1 id="news-heading">Filipino Student Teams Up with Local Artist to Develop an NFT-based Voting Platform</h1>
                    </div>
                    <div id="news-image" class="text-center">
                        <img src="../../../../assets/news-image-02.png" class="img-fluid news-img" alt="">
                    </div>
                    <div id="news-text">
                        <p id="date">November 2, 2021</p>
                        <p id="paragraph">Eighth grader Rien Lewis Pecson and artist Neil Fernandez Jr appeared in today's The Final Word with Rico Hizon on CNN Philippines. They were invited to share how iVote.ph came to be, and their plans in the future. "We want iVote to be scalable. We want it to not only be used for polls, but possibly for real elections as well, in the Philippines and also in other countries," said Rien.</p>
                        <p id="paragraph">iVote.ph is an online voting plattform that uses blockchain and NFTs to make sure that each vote is unique, secure, and unmanipulated. Each vote is tied to an artwork drawn by Neil, and all of them are visible to the public. </p>
                        <p id="paragraph">How did these two come up with the idea?</p>
                        <p id="paragraph">"For a few years, I've heard from the news, my parents, and other grown-ups about election and voting issues," Rien said. "Since I'm learning software development, I decided that I would try to help". Neil created the UI design of the website and the individual artworks of the presidential canidates. "We just want to invite more people to vote through NFTs and blockchain," he said. </p>
                        <p id="paragraph">In the future, iVote.ph wil not only be limited to politics. The duo is planning to add different categories and industries like entertainment, sports, music, and places. They also aim to expand their reach internationally, with stars from all around the world featuring on this site.</p>
                        <p id="paragraph">So how does iVote work? "It's very simple. You just choose an NFT Candidate, click the 'Vote' button on the bottom then input your mobile number. Then you type the code you receive and you're done. Your vote is now recorded into the blockchain," Rien said.</p>
                        <p id="paragraph">The voting process for iVote is very simple, but is it safe? Rien and Neil believe it is. They are always making improvements to the site's security protocols against hackers or bots.</p>
                        <p id="paragraph">Rien and Neil plan to implement a Citizen Verification System (CVS) which is similar to a Know Your Customer or KYC. This gives them the ability to add valid IDs and the voter's credentials, further making the system protected and trustworthy.</p>


                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
</template>

<script>
import Header from '../../../../components/Header.vue'
import Footer from '../../../../components/Footer.vue'

export default {
    components: {
        Header,
        Footer,
    },

    data() {
        return {
            isNewsActive: true,
        }
    }

}
</script>

<style scoped>
    .body {
        background-size:cover;
        height:100vh;
        color:black;
        background-repeat:no-repeat;
        margin: 0 auto;
        padding:20px;
    }

    .bg {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -9999;
        border:none;
        object-fit: cover;
    }

    .news-img {
        width:50rem;
        border-radius:5px;
    }

    .news-body {
        background-color:rgba(128, 128, 128, 0.2);
        border-radius:10px;
        padding:10px;
        border-color:rgb(255, 102, 0);
        border-width:15px;
    }

    #news-image {
        width:100%;
    }

    #news-text {
        width:100%;
        padding:10px;
        text-align: justify;
    }

    #news-heading {
        font-family:'PoppinsSemiBold', sans-serif;
        font-size:40px;
    }

    #news-paragraph {
        font-family:'PoppinsRegular', sans-serif;
    }

    #see-more {
        color:black;
        text-decoration:none;
        background-color:rgb(255, 102, 0);
        font-family:'PoppinsMedium', sans-serif;
    }

    #see-more:hover {
        color:white;
        text-decoration:none;
        background-color:rgb(255, 125, 0);
        font-family:'PoppinsMedium', sans-serif;
    }

    #date {
        font-family:'PoppinsRegular', sans-serif;
        font-size:12px;
    }

    @media screen and (max-width: 1024px) {
        #news-heading {
            font-family:'PoppinsSemiBold', sans-serif;
            font-size:40px;
        }

        #news-overview {
            font-family:'PoppinsRegular', sans-serif;
            font-size:12px;
        }
    }

    @media screen and (max-width: 768px) {
        #news-image {
            width:100%;
        }

        #news-text {
            width:100%;
            padding:10px;
            text-align:justify;
        }

        .news-body {
            display:block;
        }

        #news-heading {
            font-family:'PoppinsSemiBold', sans-serif;
            font-size:30px;
        }
    }

    @media screen and (max-width: 320px) {
        .body {
            padding-bottom:700px;
        }

        #news-heading {
            font-size:20px;
        }
    }
</style>