<template>
  <div>
    <splash-header></splash-header>
  <div class="body text-center" >
    <img src="../../../assets/assets-01.jpg" alt="" class="bg">
    <div class="row col-12 flex-wrap">
      <div class="container left-column text-center w-100">
        <img src="../../../assets/assets-06.png" class="logoImg" style="width:15rem; height:auto;">
        <h1 class="heading">Discover and Vote for your Favorite NFTs</h1>
        <h4 class="subheading">on the world’s first & largest blockchain based NFT election</h4>
        <!-- <router-link id="router-link" to="/presidential-election"><button style="width:20%" class="btn btn-block explore mr-1 mb-1">Explore</button></router-link> -->
        <button style="width:20%" class="btn btn-block howto ml-1 mb-1" @click="openHowto">How To iVote</button>
        <!-- <button style="width:20%" class="btn btn-block explore">How To</button> -->
      </div>
      <div class="container">
        <div class="d-flex flex-wrap headers">
          <h3>Presidential Election</h3>
          <router-link id="router-link" to="/presidential-election"><button style="width:100%" class="btn btn-block explore mx-1 mb-1">Explore</button></router-link>
        </div>
      <carousel :perPageCustom="[[320, 3], [375, 3], [600, 3], [768, 3], [1024, 4], [1515, 5], [1440, 5], [1920, 5]]" :autoplay="true" :minSwipeDistance="2" :mouse-drag="false" :autoplayTimeout="5000" class="firstThree">
        <slide class="d-flex justify-content-center">
          <router-link to="/presidential-election">
            <div class="card">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/Pacquiao.jpg" alt="Manny">
                
              <h6 class="card-text">Manny Pacquiao</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
          <router-link to="/presidential-election">
            <div class="card">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/leni.jpg" alt="Leni">
                
              <h6 class="card-text">Leni Robredo</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/presidential-election">
            <div class="card">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/Bong_bong.jpg" alt="Bongbong">
                
              <h6 class="card-text">Bongbong Marcos</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/presidential-election">
            <div class="card">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/isko.jpg" alt="Isko">
                
              <h6 class="card-text">Isko Moreno</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/presidential-election">
            <div class="card">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/lacson.jpg" alt="Ping">
                
              <h6 class="card-text">Ping Lacson</h6>
            </div>
          </router-link>
        </slide>

      </carousel>
  
    </div>
    <div class="d-flex flex-wrap mt-5 bigCon">
      <div class="container-fluid cardRow">
        <div class="d-flex flex-wrap headers">
          <h4 class="mt-1">Vice Presidential Election</h4>
          <router-link id="router-link" to="/vice-presidential-election"><button style="width:100%" class="btn btn-block explore mx-1 mb-1">Explore</button></router-link>
        </div>
      <carousel :perPageCustom="[[320, 3], [375, 3], [600, 3], [768, 3], [1024, 4], [1515, 3], [1440, 3], [1920, 3]]" :autoplay="true" :minSwipeDistance="2" :mouse-drag="false" :autoplayTimeout="5000" class="firstThree">
         <slide class="d-flex justify-content-center">
          <router-link to="/vice-presidential-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/sara.jpg" alt="Manny">
                
              <h6 class="card-text">Sara Duterte</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
          <router-link to="/vice-presidential-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/francis.jpg" alt="Leni">
                
              <h6 class="card-text">Kiko Pangilinan</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/vice-presidential-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/atienza.jpg" alt="Bongbong">
                
              <h6 class="card-text">Lito Atienza</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/vice-presidential-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/tito.jpg" alt="Isko">
                
              <h6 class="card-text">Tito Sotto</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/vice-presidential-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/walden.jpg" alt="Ping">
                
              <h6 class="card-text">Walden Bello</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/vice-presidential-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/ong.jpg" alt="Bato">
                
              <h6 class="card-text">Willie Ong</h6>
            </div>
          </router-link>
        </slide>

      </carousel>
  
    </div>
    <div class="container-fluid cardRow">
      <div class="d-flex flex-wrap headers">
          <h4 class="mt-1">Senatorial Election</h4>
          <router-link id="router-link" to="/senatorial-election"><button style="width:100%" class="btn btn-block explore mx-1 mb-1">Explore</button></router-link>
        </div>
      <carousel :perPageCustom="[[320, 3], [375, 3], [600, 3], [768, 3], [1024, 4], [1515, 3], [1440, 3], [1920, 3]]" :autoplay="true" :minSwipeDistance="2" :mouse-drag="false" :autoplayTimeout="5000" class="firstThree">
        <slide class="d-flex justify-content-center">
          <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/binay.jpg" alt="Manny">
                
              <h6 class="card-text">Jejomar Binay</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
          <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/Gordon copy.jpg" alt="Leni">
                
              <h6 class="card-text">Richard Gordon</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/zubiri.jpg" alt="Bongbong">
                
              <h6 class="card-text">Migs Zubiri</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/escudero.jpg" alt="Isko">
                
              <h6 class="card-text">Chiz Escudero</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/trillanes copy.jpg" alt="Ping">
                
              <h6 class="card-text">Sonny Trillanes</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/risa.jpg" alt="Bato">
                
              <h6 class="card-text">Risa Hontiveros</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
          <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/raffy.jpg" alt="Manny">
                
              <h6 class="card-text">Raffy Tulfo</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
          <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/loren.jpg" alt="Leni">
                
              <h6 class="card-text">Loren Legarda</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/ejercito.jpg" alt="Bongbong">
                
              <h6 class="card-text">JV Ejercito</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/herbert.jpg" alt="Isko">
                
              <h6 class="card-text">Herbert Bautista</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/monsour.jpg" alt="Ping">
                
              <h6 class="card-text">Monsour del Rosario</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/gringo.jpg" alt="Bato">
                
              <h6 class="card-text">Gringo Honasan</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
          <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/alan.jpg" alt="Manny">
                
              <h6 class="card-text">Alan Peter Cayetano</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
          <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/de lima.jpg" alt="Leni">
                
              <h6 class="card-text">Leila De Lima</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/mark.jpg" alt="Isko">
                
              <h6 class="card-text">Mark Villar</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/robin.jpg" alt="Ping">
                
              <h6 class="card-text">Robin Padilla</h6>
            </div>
          </router-link>
        </slide>
        <slide class="d-flex justify-content-center">
           <router-link to="/senatorial-election">
            <div class="card smallCard">
              <img class="card-img-top img img-fluid" src="../../../assets/web-assets/sherwin.jpg" alt="Bato">
                
              <h6 class="card-text">Sherwin Gatchalian</h6>
            </div>
          </router-link>
        </slide>

      </carousel>
  
    </div>
    </div>
      <!-- <div class="boardCon text-center d-flex flex-wrap justify-content-center mt-5">
        <div style="width:500px;">
          <h4>Blockchain Election Simulation 2022</h4>
          <div class="d-flex flex-wrap justify-content-center text-center borderLeft">
            <img src="../assets/web-assets/assets-15.png" alt="" style="object-fit:contain;width:200px;">
            <h5 class="mt-5">&</h5>
            <img src="../assets/assets-06.png" alt="" style="object-fit:contain;width:200px;">
          </div>
          <h5>Team up to host the first and largest blockchain election simulation for the Philippines</h5>
          <h3>November 17, 2021</h3>
        </div>
        <div>
          <div class="d-flex flex-wrap signTwo">
            <div class="signImages">
              <img src="../assets/web-assets/assets-13.png" class="voteImg" alt="">
              <img src="../assets/web-assets/assets-14.png" class="voteImg" alt="">
            </div>
            <div style="width:275px" class="mx-1">
              <h5>WHY VOTING MATTERS</h5>
            <h6>Strong institutions are one of the 17 Sustainable Development Goals of the United Nations, and the act of voting and appointing government officials is a right afforded to every citizen once they turn 18. They have the power to make their voices heard just by going through the process of registering.</h6>
            </div>
          </div>
        </div>
      </div> -->
      <div class="featuredCon ">
        <div class="text-center">
            <h6>Featured On:</h6>
          </div>
        <div class="d-flex justify-content-center text-center">
        <div class="newsCon text-center d-flex flex-wrap justify-content-center" style="width:1000px;">
          <a href="https://mb.com.ph/2021/10/27/twelve-year-old-filipino-student-develops-nft-based-voting-platform/" target="_blank"><img src="../../../assets/news1.png" alt=""></a>
          <a href="https://cnnphilippines.com/videos/2021/11/2/12-year-old-Filipino-develops-NFT-based-voting-platform-.html" target="_blank"><img src="../../../assets/news2.png" alt=""></a>
        </div>
      </div>
      <!-- <div class="d-flex flex-wrap justify-content-center others">
          <img src="../assets/web-assets/assets-01.png" alt="" style="width:auto;height:35px;">
          <img src="../assets/web-assets/assets-02.png" alt="" style="width:auto;height:35px;">
          <img src="../assets/web-assets/assets-03.png" alt="" style="width:auto;height:35px;">
          <img src="../assets/web-assets/assets-04.png" alt="" style="width:auto;height:35px;">
          <img src="../assets/web-assets/assets-05.png" alt="" style="width:auto;height:35px;">
          <img src="../assets/web-assets/assets-06.png" alt="" style="width:auto;height:35px;">
          <img src="../assets/web-assets/assets-07.png" alt="" style="width:auto;height:35px;">
          <img src="../assets/web-assets/assets-08.png" alt="" style="width:auto;height:35px;">
        </div> -->
      </div>
    </div>
    <!--Modals-->
    <Howto v-if="showHowto" @close="closeHowto"></Howto>
    <publicus-banner v-if="showPublicus" @close="hidePublicus"></publicus-banner>
  </div>
  <Footer></Footer>
  </div>
</template>

<script>
// import CreateAccount from './modals/signup/Create.vue'
import Footer from '../../../components/Footer.vue'
import Howto from '../../../components/modal/howto/One.vue'
import PublicusBanner from '../../../components/modal/publicus/BannerModal.vue'
import SplashHeader from '../../../components/splashHeader.vue'

export default {
  components: {
    //   CreateAccount,
      Footer,
      Howto,
      SplashHeader,
      PublicusBanner,
    },
    data: function() {
        return {
            showCreate: false,
            showHowto: false,
            showPublicus: false,
        }
    },
    methods: {
      toggleCreate() {
          this.showCreate = !this.showCreate
      },

      openHowto() {
        this.showHowto = true
      },

      closeHowto() {
        this.showHowto = false 
      },

      hidePublicus() {
        this.showPublicus = false
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .body {
    background-size:cover;
    height:100vh;
    color:white;
    padding:10px;
    background-repeat:no-repeat;
    margin: 0 auto;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    border:none;
    object-fit: cover;
  }

  .logoImg {
    width:25%;
  }

  .left-column {
    padding:30px;
  }

  .heading {
    font-size:40px;
    font-family:'PoppinsBold', sans-serif;
  }

  .subheading {
    font-size: 27px;
    font-family: 'PoppinsSemiBold', sans-serif;
  }

  .blending {
    width:100%;
  }

  .btn {
    width:100%;
  }

  .explore, .simul {
    background-color:rgb(255, 102, 0);
    border-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    margin-right:2px;
    border-width: 2px;
  }

  .explore:hover, .simul:hover {
    background-color:rgb(255, 120, 0);
    border-color:rgb(255, 120, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    margin-right:2px;
    border-width: 2px;
  }

  #router-link {
    text-decoration: none;
    color:white;
  }

  .howto {
    border-width:2px;
    border-color:white;
    color:white;
    font-family:'PoppinsMedium', sans-serif;
  }

  .howto:hover {
    background-color:rgb(255, 102, 0);
    border-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    margin-right:2px;
  }

  .create {
    background-color:white;
    color:black;
    font-family:'PoppinsMedium', sans-serif;
  }

  .about {
    background-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
  }

  .firstThree {
    margin: 0 auto;
    display: block;
    justify-content: center;
  }

  .card {
    width:15rem;
    height:20rem;
    background-image: url(../../../assets/assets-10.png);
    background-size:cover;
    background-repeat:no-repeat;
    background-color: transparent !important;
    border:none;
    font-family:'PoppinsRegular', sans-serif;
    color:black;
    padding:20px;
    font-size:12px;
  }

  .smallCard {
    width:12rem;
    height:17rem;
    background-image: url(../../../assets/assets-10.png);
    background-size:cover;
    background-repeat:no-repeat;
    background-color: transparent !important;
    border:none;
    font-family:'PoppinsRegular', sans-serif;
    color:black;
    padding:20px;
    font-size:12px;
  }

  .cardRow {
    width:50%
  }

  .img {
    border-radius:10px;
    height:15rem;
    object-fit: contain;
  }

  a {
      text-decoration:none;
  }

  .newsCon {
    padding:20px;
  }

  .featuredCon {
    margin-bottom:75px;
  }

  .newsCon img {
    border-radius:10px;
    width:470px;
    padding-bottom:10px;
    margin:5px;
  }

  .socCon img {
    margin:20px;
  }

  .others img {
    margin:5px;
  }

  .boardCon {
    background-color:rgba(50,128,128,0.1);
    padding:10px;
    width:100vw;
    justify-self:center;
  }

  .voteImg {
    object-fit:contain;
    width:100px;
  }

  .featuredCon .p {
    margin-left:10px;
  }

  .signTwo {
    width:600px;
  }

  /* .newsCon img:hover {
    box-shadow: 2.5px 5px 20px black ;
  } */

   @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }

      .newsCon {
        padding:10px;
        margin:50px 0px;
      }

    .newsCon img {
      border-radius:10px;
      width:80%;
    }

    .card{
        display: flex;
        justify-content: center !important;
      }

      .cardRow {
        width:100%;
      }

      .smallCard {
        width:14rem;
        height:20rem;
        background-image: url(../../../assets/assets-10.png);
        background-size:cover;
        background-repeat:no-repeat;
        background-color: transparent !important;
        border:none;
        font-family:'PoppinsRegular', sans-serif;
        color:black;
        padding:20px;
        font-size:12px;
      }

      .signTwo {
        width:600px;
        justify-content:center;
        text-align: center;
      }

      .voteImg {
        width:175px;
      }
          
    }
   @media screen and (max-width: 769px) {
     .body {
        height:auto;
        margin: 0;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card, .smallCard {
      width:15rem;
      height:21rem;
      font-size:10px;
    }

    .newsCon {
        padding:10px;
        margin:25px 0px;
      }

    .newsCon img {
      border-radius:10px;
      width:100%;
    }

    .cardRow, .bigCon, .firstThree {
            margin:0 auto;
            padding:5px;
            width:100% !important;
            margin-bottom:50px;
            display:block !important;
            flex-wrap:nowrap !important;
          }
    
        
  }

    @media screen and (max-width: 600px) {
      .body {
        padding:20px;
      }
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card, .smallCard {
            width:11rem;
            height:15rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }

          .simul {
            background-color:rgb(255, 102, 0);
            border-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            margin-right:2px;
            border-width: 2px;
            width:80% !important;
          }

          .explore {
            background-color:rgb(255, 102, 0);
            border-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            margin-right:2px;
            border-width: 2px;
            width:100% !important;
          }

          .howto {
            border-width:2px;
            border-color:white;
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:80% !important;
          }

          .cardRow {
            margin:0px;
            padding:0px !important;
            width:100% !important;
            margin-bottom:50px;
          }
        
    }

    @media screen and (max-width: 430px) {

       .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:7rem;
            height:9.5rem;
            padding-bottom: 9px;
          }
          .card h6{
            font-size: 8px !important;
          }
          .heading {
            font-size:15px;
          }

          .subheading {
            font-size:9px;
          }

          .explore {
            background-color:rgb(255, 102, 0);
            border-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            margin-right:2px;
            border-width: 2px;
            width:30vh !important;
          }

          .headers {
            justify-content:center;
          }

          .cardRow {
            margin:0px;
            padding:0px !important;
            width:100% !important;

            margin-bottom:50px;
          }

          .signTwo {
        width:400px;
        justify-content:center;
        text-align: center;
      }

          .voteImg {
            width:150px;
          }
    }

    @media screen and (max-width: 380px) {
      .body{
        margin: 0 auto !important;
      }
       .firstThree {
             margin: 0 auto !important;
             display: flex;
             justify-content: center;
             padding:0;
        }
         .card, .smallCard {
            width:6.4rem;
            height:8.5rem;
          }
          .card img{
            height:7.5rem !important;
          }
          .card h6{
            font-size: 6px !important;
          }
          .heading {
            font-size:15px;
          }

          .subheading {
            font-size:9px;
          }

          .socCon #socImg {
            margin:10px;
            margin-top:40px;
            width:30px;
          }

          .cardRow {
            margin:0px;
            padding:0px !important;
            width:100% !important;
            margin-bottom:50px;
          }
    }

    @media screen and (max-width: 320px){
          .card, .smallCard {
            width:5.3rem;
            height:7rem;
          }
          .card img{
            height:7rem !important;
          }

          .cardRow {
            margin:0;
            padding:0;
            margin-bottom:50px;
          }

          .voteImg {
            width:125px;
          }
    }


  /* @media screen and (max-width:800px) {
    .body {
      background-image: url(../assets/mobile/bg-11.jpg);
      background-size:cover;
      height:auto;
      color:white;
      padding:25px;
      background-repeat:no-repeat;
    }

    .logoImg {
      width:50%;
    }

    .left-column {
      padding:50px;
    }

    .heading {
      font-size:25px;
    }

    .subheading {
      font-size:12px;
    }

    button {
      margin-top:5px;
      width:50% !important;
    }
  }

  @media screen and (max-width: 320px) {
        .body {
            background-image: url(../assets/assets-01.jpg);
            background-size:cover;
            height:auto;
            color:white;
            background-repeat:no-repeat;
            padding: 0;
            margin: 0;
        }

        #heading {
            font-size:20px;
        }

        #subHeading {
            font-size:15px;
        }

        .card {
          margin-left:25px;
        }
    } */
</style>
