<template>
  <div class="backdrop">
    <div class="b-modal">
        
      <div class="modalBody modal-dialog-scrollable container justify-content-center align-items-center">
          <div class="labelWrapper">
                    <label for="" class="d-block">LOGIN</label>
        </div>

        <form class="form container">
            <div class="form-row text-center container">
                <div class="btn-group email-or-mobile">
                    <button type="button" class="btn " v-bind:class="{ active: loginvia === 'mobile' }" v-on:click="toggleLoginOption('mobile')">Mobile No.</button>
                    <button type="button" class="btn " v-bind:class="{ active: loginvia === 'email' }" v-on:click="toggleLoginOption('email')">Email Address</button> 
                </div>
            </div>
            <div class="form-row text-center container">
                <div class="form-group d-flex justify-content-center formWrapper" v-if="loginvia =='email' ">
                    <input type="loginId" class="mt-3 textField text-center" placeholder="Enter your email address" v-model="loginId">
                </div>
                <p class="help-block" ng-show="errors.loginId && errors.loginId !== ''" style="color:red;"> {{ errors.loginId }} </p>

                <div class="form-group formWrapper container" v-if="loginvia =='mobile'">
                    <div class="flex-wrap d-flex mobileWrapper mt-3 container ">
                        
                            <div class="input-group-prepend code d-flex">
                                <button class="flag-btn" type="button" v-on:click="toggleCountryList">
                                    <!-- <img class="default-flag" :src="require('../../../public/assets/signup_flags/' + country + '.png' )"> -->
                                <span class="icon"><b-icon icon="caret-down-fill"></b-icon></span></button>
                                <h6 class="mt-2 text-center" style="background-color:#efefef;">+{{ areaCode }}</h6>
                            </div>
                            <div class="country-list" v-if="showCountry">
                                <div class="row">
                                    <div class="col-sm-6" v-for="code in mobileCodes" v-on:click="selectCountry(code)" :key="code.id">
                                        <div >
                                            <img class="flag-list-img" :src="code.imgPath">
                                        </div>

                                        <div class="country-name"> <span> {{ code.country }} </span> </div>
                                
                                    </div>
                                
                                </div>
                            </div>
                            
                            <div class="col">
                                <input type="text" v-model="loginId" class=" textField">
                            </div>
                    </div>
                </div>
            </div>

            <div class="buttonWrapper container">
                <div class="">
                    <button type="submit" class="ok mt-3 btn" @click.prevent="loginAccount">OK</button>
                </div>
                <div class="">
                    <button type="submit" @click="closeModal" class="cancel mt-3 btn">CANCEL</button>
                </div>
            </div>
        </form>
      </div>
    </div>
    <registerVerify v-if="showLoginVerify" @close="closeVerify" :mobile="propsInfo"></registerVerify>
  </div>
</template>
<script>
import registerVerify from './simulation/modal/login/verifyModal.vue'

export default {
    name: "Login",
    components: {
        registerVerify
    },
    data: function(){
        return {
            loginId: '',
            propsInfo: '',
            loginvia:'mobile',
            emailAddress:'',
            selectedMobile: {},
            showCountry: false,
            countryCode: 'PH',
            areaCode: '63',
            mobileNumber: '',
            errors:{
                loginId:'',
            },
            signupHost: 'ivote2022.cloudchain.it',
            showLoginVerify: false,
            idOfUser: '',
        }
    },
    computed: {
        mobileCodes() {
            return this.$store.state.mobileCodes;
        }
    },

    mounted() {
      this.$store.dispatch("mobileCodes");
    },

    beforeMount() {
        this.changeFlag();
    },
    watch: {
 		'loginvia': function(newValue, oldValue) {
	        if(newValue !== oldValue) {  
	        	this.loginId = "";

 				if(newValue == 'mobile') {
		   			this.loginId = this.mobileCode;
		   		}
	            
	        }
	    },

 	},

    methods: {

        closeVerify() {
            this.showLoginVerify =  false
        },
        closeModal(){
            this.$emit("close");
            
        },
        toggleLoginOption(loginvia) {
	        this.loginvia = loginvia;

	        if(loginvia == 'mobile') {
	        	this.otpMsg = 'Please enter the 6-digit code sent to your mobile number';
	        }

	        if(loginvia == 'email') {
	        	this.otpMsg = 'Please enter the 6-digit code sent to your email address';
	        }
	      },
         changeFlag() {
            this.axios
                .get("/assets/mobilecodes.json")
                .then((response) => {
                    let mobileCodes = response.data;
                    mobileCodes.forEach((element, index) => {
                        element.imgPath = '/assets/signup_flags/' + element.countryCode + '.png'
                    if(element.mobileCode == this.areaCode) {
                            this.selectedMobile = element
                            console.log(this.selectedMobile)
                            // console.log(this.imgPath)
                            // console.log('working')
                        }
                    }) 
                    console.log(this.selectedMobile)
                })
                .catch((error) => {
                    // console.log(error);
                });
        },

        selectCountry: function(c) {
            this.showCountry = false;
            this.countryCode = c.countryCode;
            this.areaCode = c.mobileCode;
            this.countryName = c.country;
        },
        toggleCountryList: function(){
            this.showCountry = !this.showCountry;
        },

        loginAccount() {
            if (this.loginvia == "mobile") {
                this.idOfUser = this.areaCode + this.loginId
                this.propsInfo = this.areaCode + this.loginId
            } else {
                this.idOfUser  = this.loginId
                this.propsInfo = this.loginId
            }
             const params = {
                    host:   this.signupHost,
                    userId:this.idOfUser,
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data,
                    url: '/cx/2fa/request',
                };
                this.axios(options)
                    .then((response) => {
                        // Success Code goes here
                        this.showLoginVerify = true
                        // localStorage.setItem("blox-authz", response.data.tokenId)
                        console.log(response)
                        
                    })
                    .catch((error) => {
                        // Error code goes here
                        // this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        if(error.response.data.fieldErrors != undefined) {
                            this.errors.loginId = error.response.data.fieldErrors[0].defaultMessage
                        }  else {
                            this.errors.loginId = error.response.data.msgText
                        }
                    });
        }
    }

}
</script>
<style scoped>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
}
.b-modal {
    width: 600px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 2;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    margin-top:200px;
    font-size:25px;
}

.modal-title{
    margin: 0 auto;
    color:black;
    font-family:'PoppinsSemiBold', sans-serif;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:30px;
    border-radius: 10px;
}
.mobileWrapper {
    display: flex;
    justify-content: center;
    width:100%;
    position:relative;
}
.email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;
}
.email-or-mobile .active {
    color: #1b63c5;
    border-bottom: 1px solid rgb(255, 102, 0);
}
.email-or-mobile:focus { 
    outline: none !important;
    border: none !important;
}

.formWrapper, .labelWrapper {
    display: flex;
    justify-content: center;
    width:100%;
}
.formWrapper{
    font-size: 20px;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    width:100%;
}
.col-sm-6 {
        display: flex;
        padding: 1em;
        align-items: center;
        cursor: pointer;
}
.col-sm-6:hover {
        background: darken(#3468c7, 10%);
    }
.country-name {
    margin-left: 0.5em;
}

span {
     font-size: 0.5em;
}
.flag-btn {
    width: 40px;
    border-radius: 3px;
    color: #47525b;        
    border: none;
    outline: none;
    box-shadow: none !important;
    border-radius: 0;
}
h6 {
    padding-right: 5px;
    font-size: 22px; 
}

.code {
    background-color:#efefef;
        
}
.danger{
    color:red;
}

.textField {
    width: 100% !important;
    border:none;
    color:#191919;
    background-color:#efefef;
    border-radius:3px;
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 22px;
     padding: 6px;
}

.textField:focus {
    border:none !important;
    outline:none !important;
}



label {
    color:black;
    font-family: 'PoppinsMedium', sans-serif;
    font-size:15px;
}

.ok {
    background-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;
    margin-right:20px !important;
}

.cancel {
    color:#191919;
    background-color:lightgray;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;
    margin-right:20px !important;
}

.checkLabel {
    font-size:12px;
    display:inline;
}

.form-check-input {
    background-color:white;
    border-width:2px;
    border-color:lightgray;
    margin-right:5px;
}

input[type=checkbox]:checked {
    background-color:blue;
    border:none;
}


.chooseLogin {
    color:black;
    font-family:'PoppinsMedium', sans-serif;
    font-size:12px;
    cursor:pointer;
}

.text-black {
    color:black;
}

#footerImg {
  width:25%;
}

#footerText {
  color:black;
  display:inline;
}

.user-country-flag {
  width: 30px;
  height: 20px;
  padding: 3px;
}

.form-group-mobile {
  display: flex;
}

.b-select {
    border-color:gray;
    border-radius:3px;
}



.checkboxGroupTwo {
    margin-left:47px;
}

#areaCode h4 {
    font-size:22px !important;
}

.form-control {
    width:75px;
    height:100%;
    font-size:35px;
}

#areaImg {
    margin-right:2px;
}

#captcha {
    margin-top:10px;
}

.flag-list-img {
      width: 33px;
    }

    .mobile-container {
      position: relative;
    }

      .default-flag {
        width: 33px;
      }

      .country-list {
          position: absolute;
          background: white;
          color: #000;
          top: 40px;
          padding: 0.5em;
          height: 13em;
          overflow: auto;
      }
          .row {
            margin: 0 !important;
          }
            .col-sm-6 {
                display: flex;
              padding: 0.5em;
              align-items: center;
              cursor: pointer;
            }
              .col-sm-6:hover {
                background: darken(#3468c7, 10%);
              }
              .country-name {
                margin-left: 0.5em;
              }

              span {
                font-size: 0.9em;
              }

              .flag-btn {
                color: #47525b;
                border: none;
                outline: none;
                box-shadow: none !important;
                border-radius: 0;
              }

              .code {
                  background-color:#efefef;
              }





    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
    

      .textField {
            width:100%;
            font-size: 15px;
            padding: 6px;
        }
        h6{
            font-size: 15px;
        }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .textField {
            width:100%;
            font-size: 15px;
            padding: 6px;
        }
        h6{
            font-size: 15px;
        }
        
  }

    @media screen and (max-width: 600px) {
         
         .textField {
            width:100%;
            font-size: 15px;
            padding: 6px;
        }
        h6{
            font-size: 15px;
        }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;
        }
        .textField {
            width:100%;
            font-size: 15px;
            padding: 6px;
        }
        h6{
            font-size: 15px;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
            width: 313px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;
        }

        .textField {
            width:100%;
            font-size: 15px;
            padding: 6px;
        }
        h6{
            font-size: 15px;
        }
        .buttonWrapper{
            display: block;
        }
        .ok {
           width: 100%;
        }

        .cancel {
            width: 100%;
        }
    }

</style>