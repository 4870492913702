<template>
  <div class="backdrop">
        <div class="publicus-banner">
            <div class="d-flex justify-content-between">
                <div></div>
                <p class="text-white" @click.self="closeModal" style="cursor:pointer;">x</p>
              </div>
                <div class="publicus-image">
                    <div class="d-flex justify-content-around">
                      <div></div>
                      <div class="text-center imageWrapper">
                        <router-link to="/"><img src="../../../assets/assets-06.png" class="logoImg"></router-link>
                        <img src="../../../assets/publicus-logo.png" class="publicusImg">
                        <router-link to="/pia2022"><button class="btn btn-send publicus-btn">Go To Survey</button></router-link>
                      </div>
                    </div>
                  </div>
                  <div class="publicus-image-mobile">
                    <div class="publicus-banner-mobile"></div>
                    <div class="d-flex justify-content-center">
                      <div class="text-center imageWrapper justify-content-center mt-5">
                        <router-link to="/"><img src="../../../assets/assets-06.png" class="logoImg" style="width:10rem;height:auto;"></router-link>
                        <img src="../../../assets/publicus-logo.png" class="publicusImg">
                        <router-link to="/pia2022"><button class="btn btn-send publicus-btn">Go To Survey</button></router-link>
                      </div>
                    </div>
                  </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {

  components: {
  },

  data() {
      return {
      }
  },
  
    methods: {
        closeModal() {
            this.$emit("close")
        },

        done() {
            window.location.reload()
        },

        toSurvey() {
            this.$router.push('/pia2022')
        }

    },

    mounted() {

    }
}
</script>

<style scoped>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
}
.publicus-banner {
    width:75%;
    height: auto;
    margin: 125px auto;
    background: black;
    border-radius: 20px;
    border-color:black;
    border-width:2px;
    z-index: 2;
    overflow-x: hidden !important;
    color:white;
    font-family:'PoppinsRegular', sans-serif;
    top:33%;
    padding:20px;
}

.publicus-image{
    width:100%;
    height:65vh;
    border-radius: 20px;
    background-image: url(../../../assets/publicus-banner.jpg);
    background-size: cover;
    padding:25px;
}

.imageWrapper {
    width:50%;
    justify-content: center;
    margin-left:600px;
    margin-top:100px;
  }

  .logoImg {
    width:10rem;
    height:auto;
  }
.publicusImg {
  width:30rem;
  height:auto;
}




.modal-header {
    background-color: white;
    margin-right:0px;
    margin-top:200px;
    font-size:25px;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:20px;
    border-radius: 10px;
}

.heading {
    font-family:'PoppinsSemiBold', sans-serif;
}

.text-right {
    color:black;
    cursor:pointer;
}

.number {
    background-color:rgb(255, 120, 0);
    width:27px;
    border-radius:14px;
    padding:2px;
    margin: 0 auto !important;
    font-family: 'PoppinsMedium', sans-serif
}

.circleCon {
    margin:0 auto !important;
}
.circles {
    background-color:gray;
    width:10px;
    height:10px;
    margin:5px;
    border-radius:11px;
    color:gray;
    font-size:1px;
}

#active {
    background-color:rgb(255, 120, 0);
    color:rgb(255, 120, 0);
}

.btn {
    background-color:black;
    border-width:2px;
    color:white;
    font-family: 'PoppinsMedium', sans-serif;
    width:30%;
}

.btn:hover {
    background-color:rgb(56, 56, 56);
    border-color:rgb(56, 56, 56);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
  }

  .publicus-image-mobile {
    padding:100px;
    display:none;
  }

  .publicus-banner-mobile{
        width:100%;
        height:30vh;
        border-radius: 20px;
        background-image: url(../../../assets/publicus-banner.jpg);
        background-size: cover;
        padding:100px;
    }

    @media screen and (max-width: 1100px) {



      .publicus-image{
          width:100%;
          height:65vh;
          border-radius: 20px;
          background-image: url(../../../assets/publicus-banner.jpg);
          background-size: cover;
          padding:100px;
          display:none;
      }

      .imageWrapper {
        width:40%;
        justify-content:space-around;
      }

          
    }

    @media screen and (max-width:1024px) {

    .publicus-image-mobile{
        display:block;
        padding:0px;
    }

    .imageWrapper {
        width:40%;
        justify-content:space-around;
        margin-top:0px;
        margin-left:0px;
      }

      .logoImg {
        width:5rem;
        height:auto;
      }
    .publicusImg {
      width:19rem;
      height:auto;
    }

    .publicus-btn {
        width:50%;
        margin-top:5px;
    }

  
  }

  @media screen and (max-width:800px) {
    .imageWrapper {
        width:100%;
      }

      .logoImg {
        width:10rem;
        height:auto;
      }
    .publicusImg {
      width:25rem;
      height:auto;
    }
  }

@media screen and (max-width: 600px) {
        .b-modal {
            width: 600px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
            overflow: auto;
            top:0;
        }
    }

@media screen and (max-width: 420px) {
        .b-modal {
            width: 414px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
            overflow: auto;
            top:0;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px;
            border-radius: 10px;
            font-size:15px;
        }

        .imageWrapper {
            width:100%;
          }

          .logoImg {
            width:10rem;
            height:auto;
          }
          .publicusImg {
            width:15rem;
            height:auto;
          }

          .publicus-image-mobile {
            padding:10px;
          }
    }

    @media screen and (max-width: 380px) {
        .b-modal {
            width: 375px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 10px;
            border-radius: 10px;
        }

        .publicus-banner {
            top:15%;
            margin-top:50px;
        }
    }

    @media screen and (max-width: 320px) {
        .b-modal {
            width: 320px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px;
            border-radius: 10px;
        }

        .publicus-banner {
            top:15%;
            margin-top:50px;
            padding:5px;
        }
    }
</style>