<template>
  <div class="body">
      <Header :isPolActive="isPolActive"></Header>
      <div id="content" class="container-fluid">
        <h1 class="text-center text-white" id="heading">Discover and Vote for your Favorite NFTs</h1>
        <div id="card" class="container-fluid flex-wrap justify-content-center text-left">
          <prodCard id="prodCard" v-for="nft in nfts.slice(0,3)" :key="nft.id" :nft="nft"></prodCard>
        </div>
        <div id="card" class="container-fluid flex-wrap justify-content-center">
          <prodCard id="prodCard" v-for="nft in nfts.slice(3, 6)" :key="nft.id" :nft="nft"></prodCard>
        </div>
      </div>
      <Footer></Footer>
  </div>
</template>

<script>
import prodCard from '../prodCard.vue'
import Header from '../Header.vue'
import Footer from '../Footer.vue'

export default {
  components: {
    prodCard,
    Header,
    Footer,
  },

  data() {
    return {
      isPolActive: true,
    }
  },

  mounted() {
    this.$store.dispatch("getNFTS");
  },

  computed: {
    nfts(){
      return this.$store.state.nfts
    }
  }
}
</script>

<style scoped>
  .body  {
    background-image: url(../../assets/assets-01.jpg);
    background-size:cover;
    background-repeat: no-repeat;
  }

  #content {
    margin-top:50px;
    padding:50px;
    height: auto;
    padding-bottom:260px;
  }

  #card {
    display:flex;
  }

  #prodCard {
    margin-bottom:100px;
  }

  #heading {
    font-size:50px;
  }
</style>