<template>
        <div>
            <Header :isNewsActive="isNewsActive"></Header>
            <div class="body">
                <img src="../../../assets/assets-01.jpg" alt="" class="bg">
                <div class="container-fluid news-body" id="news-one">
                    <div id="news-image">
                        <img src="../../../assets/news-image-01.png" class="img-fluid news-img" alt="">
                    </div>
                    <div id="news-text">
                        <h4 id="news-heading">12-Year Old Filipino Student Develops NFT-based Voting Platform</h4>
                        <p id="date">October 27, 2021</p>
                        <p id="news-overview">Blockchain projects keep on exploring new ways to disrupt the world — from finance to real estate, to food supply chains, to art, and most recently, voting. Countries like the United States, Russia, Thailand, and South Korea have already utilized blockchain technologies to conduct voting processes for their citizens as the exercise is an important part of any democratic process.</p>
                        <p id="news-overview"> Could this become a possibility in the Philippines?</p>
                        <router-link to="/12-year-old-filipino-student-develops-nft-based-voting-platform"><button class="btn" id="see-more">See More</button></router-link>
                    </div>
                </div>

                <div class="container-fluid news-body" id="news-two">
                    <div id="news-image">
                        <img src="../../../assets/news-image-02.png" class="img-fluid news-img" alt="">
                    </div>
                    <div id="news-text">
                        <h4 id="news-heading">Filipino Student Teams Up with Local Artist to Develop an NFT-based Voting Platform</h4>
                        <p id="date">November 2, 2021</p>
                        <p id="news-overview">Eighth grader Rien Lewis Pecson and artist Neil Fernandez Jr teamed up to create iVote.ph, a voting platform that uses non-fungible tokens to record votes into the blockchain, making the votes both secure and visible to the public.</p>
                        <p id="news-overview">Let's learn more about this innovation from the creators themselves.</p>
                        <a href="https://cnnphilippines.com/videos/2021/11/2/12-year-old-Filipino-develops-NFT-based-voting-platform-.html" target="_blank"><button class="btn" id="see-more">See More</button></a>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
</template>

<script>
import Header from '../../../components/Header.vue'
import Footer from '../../../components/Footer.vue'

export default {
    components: {
        Header,
        Footer,
    },

    data() {
        return {
            isNewsActive: true,
        }
    }

}
</script>

<style scoped>
    .body {
        background-size:cover;
        height:100vh;
        color:black;
        background-repeat:no-repeat;
        margin: 0 auto;
        padding:20px;
    }

    .bg {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -9999;
        border:none;
        object-fit: cover;
    }

    .news-img {
        width:100%;
        border-radius:5px;
    }

    .news-body {
        display:flex;
        flex:wrap;
        background-color:rgba(128, 128, 128, 0.2);
        border-radius:10px;
        padding:10px;
        border-color:rgb(255, 102, 0);
        border-width:15px;
        margin-bottom:10px;
    }

    #news-image{
        width:600px;
        height:auto;
        object-fit: contain;
    }

    #news-text {
        width:50%;
        padding:10px;
        align-items:center;
    }

    #news-heading {
        font-family:'PoppinsSemiBold', sans-serif;
    }

    #news-overview {
        font-family:'PoppinsRegular', sans-serif;
    }

    #see-more {
        color:black;
        text-decoration:none;
        background-color:rgb(255, 102, 0);
        font-family:'PoppinsMedium', sans-serif;
    }

    #see-more:hover {
        color:white;
        text-decoration:none;
        background-color:rgb(255, 125, 0);
        font-family:'PoppinsMedium', sans-serif;
    }

    #date {
        font-family:'PoppinsRegular', sans-serif;
        font-size:12px;
    }

    @media screen and (max-width: 1024px) {
        #news-heading {
            font-family:'PoppinsSemiBold', sans-serif;
            font-size:15px;
        }

        #news-overview {
            font-family:'PoppinsRegular', sans-serif;
            font-size:12px;
        }
    }

    @media screen and (max-width: 768px) {
        #news-image {
            width:100%;
        }

        #news-text {
            width:80%;
            padding:10px;
        }

        .news-body {
            display:block;
        }
    }

    @media screen and (max-width: 320px) {
        .body {
            padding-bottom:700px;
        }
    }
</style>