import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Carousel3d from 'vue-carousel-3d';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
import { store } from './store/store'
import VueCarousel from 'vue-carousel';
import VueSelectImage from 'vue-select-image'
import Particles from "particles.vue";

Vue.use(Particles);
// add stylesheet
require('vue-select-image/dist/vue-select-image.css')

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
Vue.use(Carousel3d);
Vue.use(VueCarousel);
Vue.use(VueSelectImage);

Vue.config.productionTip = false

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'; 
axios.defaults.headers.common['blox-authz'] = localStorage.getItem("tokenId");
axios.defaults.headers.common['imgUrl'] = localStorage.getItem("imgUrl");

new Vue({
  store: store,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
  }

  return value
})

