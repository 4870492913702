<template>
  <div class="backdrop">
    <div class="b-modal">
        
      <div class="modalBody modal-dialog-scrollable container justify-content-center align-items-center">
        <form class="form container" @submit.prevent="verify()">
            <!-- <p class="text-red">{{ errorMsg }}</p> -->
            <div class="otpWrapper container-fluid d-flex flex-wrap">
                <label class="otpLabel">Please enter the 6-digit code sent to <span id="highlight">{{ mobile }}</span></label>
                <div class="flex-wrap d-flex otp pinWrapper mt-3 container-fluid">
                    <div class="">
                        <input type="text" maxlength="1" v-model="otp[0]" id="pin1" @keydown="pinBack($event, 1)" required>
                    </div>
                    <div class="">
                        <input type="text" maxlength="1" v-model="otp[1]" id="pin2" @keydown="pinBack($event, 2)" required>
                    </div>
                    <div class="">
                        <input type="text" maxlength="1" v-model="otp[2]" id="pin3" @keydown="pinBack($event, 3)" required>
                    </div>
                    <div class="">
                        <input type="text" maxlength="1" v-model="otp[3]" id="pin4" @keydown="pinBack($event, 4)" required>
                    </div>
                    <div class="">
                        <input type="text" maxlength="1" v-model="otp[4]" id="pin5" @keydown="pinBack($event, 5)" required>
                    </div>
                    <div class="">
                        <input type="text" maxlength="1" v-model="otp[5]" id="pin6" @keydown="pinBack($event, 6)" required>
                    </div>
                </div>
                <div>
                    <p class="text-red">{{ errorMsg }}</p>
                </div>
               <div class="msgCon text-center d-block">
                    <p class="text-small text-muted">Code may be delayed due to network area coverage. Please be patient or try again in a location with better signal.</p>
                </div>
            </div>
            <div class="timerContainer">
                <p v-if="!showResend">{{ timeFormat(timerInSeconds) }}</p>
                <a href="#" @click="resendCode" v-if="showResend" style="text-decoration:none; color:black;"><p>Resend Code</p></a>
                <!-- <a href="#" @click="sendemail" v-if="showResend" style="text-decoration:none; color:black;"><button class="btn">Send via Email</button></a> -->
            </div>

            <div class="buttonWrapper">
                <div class="">
                    <button type="submit" class="submit mt-3 btn">CONFIRM</button>
                </div>
                <div class="">
                    <button @click="closeModal" class="cancel mt-3 btn">CANCEL</button>
                </div>
            </div>
        </form>
      </div>
    </div>
    <votedModal :userId="userId" v-if="showVoted"></votedModal>
    <!-- <emailModal v-if="showEmail" @close="closeEmail" :mobileCode="mobileCode" :mobile="mobile"></emailModal> -->
    <!-- <optionModal v-if="showResend" :mobile="mobileCode + mobile" @resend="resendCode"></optionModal> -->
  </div>
</template>

<script>
import votedModal from './confirmModal.vue'
// import emailModal from './emailModal.vue'
// import optionModal from './optionModal.vue'

export default {
    props: ["mobile", "mobileCode", "captcha"],
    components: {
        votedModal,
        // emailModal,
        // optionModal 
    },
    data: function() {
        return {
            otp: [],
            initData: {
                host: 'ivote2022.cloudchain.it',
                mobileNumber: '',
            },
            showVoted: false,
            errorMsg: '',
            showResend: false,
            timerInSeconds: 90,
            userId: "",
            showEmail: false
        }
    },

    computed: {
        LmobileCodes() {
            return this.$store.state.LmobileCodes;
        }
    },

    mounted() {
      this.$store.dispatch("mobileCodes");
      this.timer();
    },

    watch: {
        'signupData.loginId': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.errorMsg = ''
            }
        },
        'signupData.loginPass': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.errorMsg = ''
            }
        },
    },

    methods: {
        closeModal(){
            this.$emit('close')
            this.showVoted = false
            this.otp = []
            this.errorMsg = ''
        },

        closeEmail() {
            this.showEmail = false
        },

        resendCode() {
            const params = {
                host:   this.initData.host,
                mobileNumber: this.mobile,
                // captcha: this.captcha
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/vote/resend/otp',
                };
                this.axios(options)
                    .then((response) => {
                        // Success Code goes here
                        // console.log('success')
                        this.showVoted = true
                        this.refreshChart();
                        this.txnHash = response.data.txnHash;
                        console.log(this.txnHash)
                        console.log('succcess')
                    })
                    .catch((error) => {
                        // Error code goes here

                        console.log(error)
                        if(error.response.data.fieldErrors != undefined) {
                            this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        } else {
                            this.errorMsg = error.response.data.msgText
                        }
                        // console.log("this.errorMsg")
                    });
        },

        verify() {
            const params = {
                signupHost:   this.initData.host,
                loginId: this.mobile,
                otp: this.otp[0] + this.otp[1] + this.otp[2] + this.otp[3] + this.otp[4] + this.otp[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/signup/2fa/verify',
                };
                this.axios(options)
                    .then((response) => {
                        // Success Code goes here
                        // console.log('success')
                        this.showVoted = true
                        this.refreshChart();
                        this.userId = response.data.userId;
                        console.log(this.txnHash)
                        console.log('succcess')
                        localStorage.setItem("blox-authz", response.data.tokenId)
                        localStorage.setItem("userId", response.data.userId)
                        localStorage.setItem("userName", response.data.userName)
                    })
                    .catch((error) => {
                        // Error code goes here

                        console.log(error)
                        if(error.response.data.fieldErrors != undefined) {
                            this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        } else {
                            this.errorMsg = error.response.data.msgText
                        }
                        // console.log("this.errorMsg")
                    });
        },

        sendcode() {
                // console.log('send code button click')
                const params = {
                    host:   this.initData.host,
                    mobileNumber: this.mobileCode + this.mobile[0] + this.mobile[1] + this.mobile[2] + this.mobile[3] + this.mobile[4] + this.mobile[5] + this.mobile[6] + this.mobile[7] + this.mobile[8] + this.mobile[9],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data,
                    url: '/cx/vote/init',
                };
                this.axios(options)
                    .then((response) => {
                        // Success Code goes here
                        // console.log(response)
                        // this.showVerify = true
                        this.errorMsg = ''
                        this.notSelected = ''
                        this.showVerify = true
                        this.timer();
                        this.showResend = false

                    })
                    // .catch((error) => {
                    //     // Error code goes here
                    //     // this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                    //     if(error.response.data.fieldErrors != undefined) {
                    //         this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                    //     }  else {
                    //         this.errorMsg = error.response.data.msgText
                    //     }
                    // });
        },

        sendemail() {
            this.showEmail = true
        },

        refreshChart() {
            //this.$parent.generateChart();
            // console.log('refreshing chart')
        },

        register() {
            // console.log(this.otp)
            // console.log(this.mobile)
        },

        pinBack: function (event, currrentBox) {
            if (event.keyCode === 8) {
                if (currrentBox != 1) {
                    setTimeout(function () {
                        document.getElementById("pin" + (currrentBox - 1)).focus();
                    }, 50);
                 }
            }

            if (
                (event.keyCode >= 48 && event.keyCode <= 57) ||
                (event.keyCode >= 96 && event.keyCode <= 105) ||
                event.keyCode == 229
            ) {
                if(currrentBox < 6) {
                    setTimeout(function () {
                        document.getElementById("pin" + (currrentBox + 1)).focus();
                    }, 50);
                }
            }

            if (event.keyCode === 37) {
                if (currrentBox != 1) {
                    setTimeout(function () {
                        document.getElementById("pin" + (currrentBox - 1)).focus();
                    }, 50);
                 }
            }
            if (event.keyCode === 39) {
                if(currrentBox < 6) {
                    setTimeout(function () {
                        document.getElementById("pin" + (currrentBox + 1)).focus();
                    }, 50);
                }
            }
        
        },
        // mobBack: function (event, currrentBox) {
        //     if (event.keyCode === 8) {
        //         if (currrentBox != 1) {
        //             setTimeout(function () {
        //                 document.getElementById("mobile" + (currrentBox - 1)).focus();
        //             }, 50);
        //          }
        //     }

        //     if (
        //         (event.keyCode >= 48 && event.keyCode <= 57) ||
        //         (event.keyCode >= 96 && event.keyCode <= 105) ||
        //         event.keyCode == 229
        //     ) {
        //         setTimeout(function () {
        //             document.getElementById("mobile" + (currrentBox + 1)).focus();
        //         }, 50);
        // }
        // },

        timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0) {
                    clearInterval(counterInterval)
                    this.showResend = true
                    this.timerInSeconds = 90
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        }
    },
}
</script>

<style scoped>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
}
.b-modal {
    width: 600px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 2;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    margin-top:200px;
    font-size:25px;
}

.modal-title{
    margin: 0 auto;
    color:black;
    font-family:'PoppinsSemiBold', sans-serif;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;
}

.timerContainer {
    display: flex;
    justify-content: center;
    width:100%;
}

.otpWrapper {
    display:flex;
    justify-content: center;
    width:100%;
}

.pinWrapper {
    display:flex;
    justify-content: center;
    width:100%;
}

.buttonWrapper {
    display:flex;
    justify-content: center;
    width:100%;
}

.form {
    width:100%;
    margin: auto auto !important;
}

.textField {
    width:360px;
    padding:5px;
    border:none;
    color:#191919;
    background-color:lightgray;
    border-radius:3px;
    font-family: 'PoppinsRegular', sans-serif;
}

label {
    color:black;
    font-family: 'PoppinsMedium', sans-serif;
    font-size:15px;
}

.submit {
    background-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;
    margin-right:20px !important;
}

.ok {
    background-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;
    margin-right:20px !important;
}

#highlight {
    color: rgb(255, 102, 0);
    font-weight:bold;
}

.cancel {
    color:#191919;
    background-color:lightgray;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;

}

.checkLabel {
    font-size:12px;
    display:inline;
}

.form-check-input {
    background-color:white;
    border-width:2px;
    border-color:lightgray;
    margin-right:5px;
}

input[type=checkbox]:checked {
    background-color:blue;
    border:none;
}

.show {
    color:black;
    font-family:'PoppinsMedium', sans-serif;
    font-size:12px;
    cursor:pointer;
}

.showPass {
    text-align:right;
}

.chooseLogin {
    color:black;
    font-family:'PoppinsMedium', sans-serif;
    font-size:12px;
    cursor:pointer;
}

.text-black {
    color:black;
}

#captchaImg {
    background-color:black;
    border-radius:10px;
}

.form-control {
    width:100%;
    height:100%;
    font-size:40px;
}

#footerImg {
  width:25%;
}

#footerText {
  color:black;
  display:inline;
}

#pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
    width:30px;
    padding:5px;
    text-align:center;
    border:none;
    color:#191919;
    background-color:lightgray;
    border-radius:3px;
    font-family: 'PoppinsRegular', sans-serif;
}

.user-country-flag {
  width: 30px;
  height: 20px;
  padding: 3px;
}

.form-group-mobile {
  display: flex;
}

.b-select {
    border-color:gray;
    border-radius:3px;
}

.sendCode {
    font-size:12px;
    margin-top: 5px;
    text-align:right;
    width:350px;
    font-weight:bold;
    cursor:pointer;
}

#pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
    width:50px;
    height:80px;
    padding:5px;
    text-align:center;
    border:none;
    color:#191919;
    background-color:lightgray;
    border-radius:3px;
    font-family: 'PoppinsRegular', sans-serif;
    font-size:30px;
    margin-right:10px;
    display: flex;
}

#mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
    width:30px;
    padding:5px;
    text-align:center;
    border:none;
    color:#191919;
    background-color:lightgray;
    border-radius:3px;
    font-family: 'PoppinsRegular', sans-serif;
    margin:1px;
}

.text-small {
    font-size:12px;
    width:100%;
}

.msgCon {
    width:100%;
    display: block !important;
}

.timerContainer .btn {
    font-size:10px;
    margin-left:5px;
    font-family: 'PoppinsSemiBold', sans-serif;
    background-color:lightgray;
    color:black;
}

.timerContainer button:hover {
    background-color:gray;
    color:white;
}

    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;
        }
        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:10px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:10px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
            margin-left:50px;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }
        
    }
</style>