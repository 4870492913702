<template>
  <div id="body" class="container-fluid footer d-flex text-center flex-wrap">
    
    <p id="footerText" class="poweredBy"><img src="../assets/footer-logo.png" id="footerImg" alt="">Blockchain by Cloudchain</p>
    <p id="footerText" class="margined">© Copyright 2021 iVote.ph </p>
    <p id="footerText"><a href="mailto:info@ivote.ph" style="text-decoration:none; color:gray;"> Contact Us: info@ivote.ph</a></p>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    #body {
        background-color:black;
        margin: 0 auto;
        color:gray;
        display: flex;
        text-align: center;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        height:20px;
    }
    #footerText {
        font-family:'PoppinsMedium';
        padding-top: 5px;
        font-size:7.5px;
    }

    #footerImg {
      display:inline;
      width:1rem;
      margin-right:5px;
    }

    @media screen and (max-width: 800px) {
         #footerText{
             font-size: 5px;
         }
        
    }

    @media screen and (max-width: 420px) {
         #footerText{
             font-size: 3px;
         }
        
    }
</style>