<template>
    <div>
        <div class="body">
            <h4><router-link style="text-decoration: none; font-weight:bold; color:white;" to="/senatorial-election">← Go Back</router-link></h4>
            <div class="container-fluid text-center justify-content-center flex-wrap d-flex">
                <div class="mx-auto cards">
                    <profileCard :name="selectedCandidate.name" :imgUrl="selectedCandidate.imgUrl"></profileCard>
                    <button class="btn mb-5" @click="openModal">{{ ifVote }}</button>
                </div>
                <div class="container-fluid col-lg-6">
                    <canvas width="400" height="305" id="candidateChart"></canvas>
                    <div id="hashTable" style="overflow-y:scroll;">
                    <table>
                        <thead>
                            <tr id="heading">
                            <th>Hash</th>
                            <th>Description</th>
                            <th>Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="data in filteredArray" :key="data.id">
                                <a :href="'https://explorer.cloudchain.it/#/transaction/' + data.txnHash" target="_blank"><td id="hash">{{ data.txnHash | truncate(20)}}</td></a>
                                <td>{{ data.txnDesc }}</td>
                                <td>{{ formatDate(data.txnDate) }}</td> 
                            </tr>
                        </tbody>
                        </table> 
                        </div>
                    </div>
                </div>
            </div>
        <Footer></Footer>
    </div>
</template>

<script>
import profileCard from '../../../../components/profileCard.vue'
import Footer from '../../../../components/Footer.vue'
import Chart from 'chart.js/auto'
import moment from 'moment'

export default {
    props: ["imgUrl"],
    components: {
        profileCard,
        Footer,
    },
  data() {
      return {
                selectedCandidate: {

                },
                selectedHistory: {},
                historyArr: [],
                txnData: {},
                filteredArray: [],
              showModal: false,
              ifVote: 'VOTE'
      }
  },

  computed: {
    nfts(){
      return this.$store.state.nfts
    }
  },


  methods: {
    openModal() {
        this.$router.push({ name: 'snFormModal', params: { id: this.$route.params.id}})
        // console.log(this.nfts)
    },

    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      return date;
    },

    closeModal() {
        this.showModal = false
    },

    generateChart() {
      const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/candidates/senator',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                let candidateArray = [];
                    let voteCountArray = [];
                    let colorContainer = [];
                    let borderContainer = [];
                    response.data.forEach((data) => {
                        candidateArray.push(data.name)
                        voteCountArray.push(data.voteCount)
                        if (data.entityId == this.selectedCandidate.entityId) {
                            colorContainer.push('rgb(255, 94, 0)')
                            borderContainer.push('rgb(255, 159, 64)')
                        } else {
                            colorContainer.push('rgba(128, 128, 128, 0.2)')
                            borderContainer.push('rgb(192, 192, 192)')
                        }
                    });
                        let fchart = document.getElementById("candidateChart").getContext('2d');

                          let fiat = new Chart(fchart, {
                            type:'bar', //line chart
                            data:{
                                labels:candidateArray,
                                datasets: [{
                                    label: 'Votes',
                                    data:voteCountArray,
                                    backgroundColor: colorContainer,
                                    borderColor: borderContainer,
                                    borderWidth: 1,
                                }]
                            },


                            options:{
                                legend:{
                                    display:false,
                                },
                              //   scales: {
                              //     yAxes: [{
                              //         ticks: {
                              //             beginAtZero:true
                              //         }
                              //     }]
                              // }
                            }
                          });
            })
            .catch((error) => {

            });
    },

    getCandidates() {
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/candidates/senator',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                response.data.forEach((element, index) =>{
                    if(element.entityId == this.$route.params.id) {
                        this.selectedCandidate = element
                    }
                });
            })
            .catch((error) => {

            });
    },

    getHistory() {
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/txn/recent/ivote?rowsPerPage=100',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                response.data.forEach((element, index) =>{
                    // console.log(element.txnDesc + ' ' + 'Vote submitted to' + ' ' + this.selectedCandidate.name)
                    if(element.txnDesc == 'Vote submitted to ' + this.selectedCandidate.name) {
                        this.selectedHistory = element
                        this.historyArr.push(this.selectedHistory)
                        
                    }
                });
            })
            .catch((error) => {
                console.log(error)
            });
    },

    formatDate: function(date) {
           // return date;
            return moment(date).format('MM/DD/YYYY hh:mm:ss A');
        },

    filterHash() {
      const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/txn/recent/ivote?position=sen',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                this.txnData = response.data;
                console.log(this.txnData)


                response.data.forEach((element, index) =>{
                        let mainString = element.txnDesc.replaceAll(" ", "")
                        let candidateName = this.selectedCandidate.name.replaceAll(" ", "")
                        if(mainString.includes(candidateName)) {
                            this.filteredArray.push(element)
                            console.log(this.filteredArray)
                        }

                        console.log(mainString.includes(candidateName))
                });
            })
            .catch((error) => {

            });
  },
  },


  mounted() {
    this.getCandidates();
    this.generateChart();
    this.getHistory();
    this.filterHash();
  },
}
</script>

<style scoped>
    .body  {
        background-image: url(../../../../assets/assets-01.jpg);
        background-size:cover;
        background-repeat: no-repeat;
        padding:50px;
        height: auto;
        padding-bottom:260px;
    }
    .btn {
        background-color:rgb(255, 94, 0);
        color:white;
        font-weight:bold;
        width:100%;
        padding:50px;
    }

    .profCard {
        align-self: center;
    }

    .currentVotes {
        border-color:rgb(128, 128, 128);
        background:transparent;
        border-width:1px;
        color:black;
        font-size:20px;
    }

    #candidateChart {
            background-color:black;
            padding:10px;
            border-radius:10px;
            margin-left:20px;
            height:100px;
        }

    #hash {
    white-space: nowrap !important; 
    width: 10px !important; 
    overflow: hidden !important;
    text-overflow: ellipsis !important; 
  }

  #hashTable {
    background-color:black;
    border-radius:10px;
    color:gray;
    font-family:'PoppinsMedium', sans-serif;
    width:43vw;
    padding:20px;
    font-size:12px;
    height:35vh;
    padding-top:0px;
    margin-top:7px;
    margin-left:20px;
  }

  #hashTable table {
    width:100%;
  }

  #hashTable th {
    font-family:'PoppinsBold', sans-serif;
    font-size:15px;
    color:white;
    padding-bottom:10px;
    position:sticky;
    top:0;
    background-color:black;
    padding-top:10px;
  }

  #hashTable a {
    text-decoration: none;
    color:gray;
  }

  @media screen and (min-width:1920px) {
    #hashTable {
      background-color:black;
      border-radius:10px;
      color:gray;
      font-family:'PoppinsMedium', sans-serif;
      margin:0 auto;
      width:45vw;
      padding:20px;
      padding-top:0px;
      height:35vh;
      margin-top:15px;
      margin-left:15px;
    }
  }

  @media screen and (max-width:1024px) {
    #hashTable {
      background-color:black;
      border-radius:10px;
      color:gray;
      font-family:'PoppinsMedium', sans-serif;
      margin:auto;
      width:40vw;
      padding:10px;
      padding-top:0px;
      height:35vh;
      margin-top:15px;
      margin-left:17px;
    }
  }

  @media screen and (max-width:800px) {
    #hashTable {
      height:35vh;
      margin-top:15px;
      width:59vh;
    }
  }

  @media screen and (max-width:600px) {
    #hashTable {
      height:35vh;
      margin-top:15px;
      width:55vh;
    }
  }

    @media screen and (max-width: 420px) {
        .body {
            padding: 10px;
            margin: 0;
        }

        .btn {
            width:75%;
            margin-top:20px;
            margin-left:15px;
        }

        #candidateChart {
            /* background-color:rgb(255, 94, 0); */
            padding:10px;
            border-radius:10px;
            margin-left:10px;

        }

        #hashTable {
      background-color:black;
      border-radius:10px;
      color:gray;
      font-family:'PoppinsMedium', sans-serif;
      margin:auto;
      width:82vw;
      padding:10px;
      font-size:10px;
      height:35vh;
      padding-top:0px;
      margin-top:15px;
      margin-left:10px;
      margin-bottom:20px;
    }

    #hashTable th {
      font-family:'PoppinsBold', sans-serif;
      font-size:12px;
      color:white;
    }
    }

    #candidateChart {
        /* background-color:rgb(255, 94, 0); */
        padding:10px;
        border-radius:10px;
    }

    @media screen and (max-width: 375px) {
        #hashTable {
            background-color:black;
            border-radius:10px;
            color:gray;
            font-family:'PoppinsMedium', sans-serif;
            margin:auto;
            width:80vw;
            padding:10px;
            font-size:10px;
            height:35vh;
            padding-top:0px;
            margin-top:15px;
            margin-left:10px;
            margin-bottom:20px;
        }
    }

    @media screen and (max-width: 320px) {

        .btn {
            width:100%;
            margin-top:20px;
            margin-left:10px;
        }

        #candidateChart {
            /* background-color:rgb(255, 94, 0); */
            padding:10px;
            border-radius:10px;
            margin:0;
            margin-left:10px;
        }

        #hashTable {
      background-color:black;
      border-radius:10px;
      color:gray;
      font-family:'PoppinsMedium', sans-serif;
      margin:auto;
      width:76vw;
      padding:5px;
      font-size:7px;
      height:28vh;
      padding-top:0px;
      margin-top:15px;
      margin-left:10px;
      margin-bottom:20px;
    }

    #hashTable th {
      font-family:'PoppinsBold', sans-serif;
      font-size:10px;
      color:white;
    }
    }
</style>