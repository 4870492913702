<template>
    <div>
        <router-link :to="{ name: 'VProfile', params: {id: nft.entityId }}">
            <div class="card d-flex">
                <img class="card-img-top img-fluid" :src="nft.imgUrl">
                <div class="card-body">
                <h6 class="card-text">{{ nft.name }}</h6>
                </div>
            </div>
        </router-link>
        <profileCard class="d-none" :imgUrl="nft.imgUrl"></profileCard>
    </div>
</template>

<script>
import profileCard from './profileCard.vue'

export default {
    props: ["nft"],

    components: {
        profileCard,
    }
}
</script>

<style scoped>
  .card {
    width:16rem;
    height:22rem;
    background-image: url(../assets/assets-10.png);
    background-size:cover;
    background-repeat:no-repeat;
    background-color: transparent !important;
    border:none;
    font-family:'PoppinsRegular', sans-serif;
    color:black;
    padding:10px;
    /* margin-right:100px !important; */
  }

  img {
    border-radius:10px !important;
    height:15rem;
    object-fit: contain;
  }

  a {
      text-decoration:none;
  }

   @media screen and (max-width:1024px) {
    img {
            height:17rem;
        }

        .card {
            width:16rem;
            height:22rem;
        }
  }

  @media screen and (max-width: 800px) {

        img {
            height:15rem;
        }

        .card {
            width:14rem;
            height:19rem;
        }

        .card-text {
            font-size:1rem;
        }
    }

    @media screen and (max-width: 440px) {
      

        .btn {
            width:100%;
            margin-top:20px;
        }

        img {
            height:9rem;
        }

        .card {
            width:9rem;
            height:12rem;
        }

        .card-text {
            font-size:0.5rem;

        }
    }

    @media screen and (max-width: 325px) {
        .body {
            padding: 10px;
            margin: 0;
        }

        .btn {
            width:100%;
            margin-top:20px;
        }

        img {
            height:9rem;
        }

        .card {
            width:7.7rem;
            height:12rem;
        }

        .card-text {
            font-size:0.5rem;
        }
    }
</style>