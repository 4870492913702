import Vue from 'vue'
import VueRouter from 'vue-router'

import afterVote from '../views/politics/ph/pres/afterVote.vue'
import VafterVote from '../views/politics/ph/vp/vAfterVote.vue'
import SafterVote from '../views/politics/ph/sen/sAfterVote.vue'
import Pres from '../views/politics/ph/pres/Pres.vue'
import VPres from '../views/politics/ph/vp/Vpres.vue'
import Sen from '../views/politics/ph/sen/Sen.vue'
import Splash from '../views/politics/ph/Splash.vue'
import PublicusSplash from '../views/politics/ph/PublicusSplash.vue'
import Profile from '../views/politics/ph/pres/Profile.vue'
import VProfile from '../views/politics/ph/vp/VProfile.vue'
import SProfile from '../views/politics/ph/sen/SProfile.vue'
import NewsHome from '../views/politics/ph/News.vue'
import Register from '../components/Register.vue'
import Simulation from '../views/politics/ph/simulation/Simulation.vue'
import VSimulation from '../views/politics/ph/simulation/VSimulation.vue'
import SimProfile from '../views/politics/ph/simulation/SimProfile.vue'
import SimVProfile from '../views/politics/ph/simulation/SimVProfile.vue'

import SimafterVote from '../views/politics/ph/simulation/SimAfVote.vue'
import SimVafterVote from '../views/politics/ph/simulation/SimVAfVote.vue'

import formModal from '../views/politics/ph/pres/formModal.vue'
import verifyModal from '../views/politics/ph/pres/verifyModal.vue'
import votedModal from '../views/politics/ph/pres/votedModal.vue'
import optionModal from '../views/politics/ph/pres/optionModal.vue'
import emailModal from '../views/politics/ph/pres/emailModal.vue'
import emailVerify from '../views/politics/ph/pres/verifyEmail.vue'

import vpFormModal from '../views/politics/ph/vp/vpFormModal.vue'
import vpVerifyModal from '../views/politics/ph/vp/vpVerifyModal.vue'
import vpVotedModal from '../views/politics/ph/vp/vpVotedModal.vue'
import vpOptionModal from '../views/politics/ph/vp/vpOptionModal.vue'
import vpEmailModal from '../views/politics/ph/vp/vpEmailModal.vue'
import vpEmailVerify from '../views/politics/ph/vp/vpVerifyEmail.vue'

import snFormModal from '../views/politics/ph/sen/snFormModal.vue'
import snVerifyModal from '../views/politics/ph/sen/snVerifyModal.vue'
import snVotedModal from '../views/politics/ph/sen/snVotedModal.vue'
import snOptionModal from '../views/politics/ph/sen/snOptionModal.vue'
import snEmailModal from '../views/politics/ph/sen/snEmailModal.vue'
import snEmailVerify from '../views/politics/ph/sen/snVerifyEmail.vue'

import ArticleOne from '../views/politics/ph/articles/One.vue'
import ArticleTwo from '../views/politics/ph/articles/Two.vue'

import Politics from '../components/filters/Politics.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash
  },

  {
    path: '/pia2022',
    name: 'PublicusSplash',
    component: PublicusSplash
  },

  {
    path: '/presidential-election',
    name: 'Pres',
    component: Pres
  },

  {
    path: '/simulation/president/:userId',
    name: 'Simulation',
    component: Simulation,
    props:true,
  },

  {
    path: '/simulation/vice-president/:userId',
    name: 'VSimulation',
    component: VSimulation,
    props:true,
  },

  {
    path: '/vice-presidential-election',
    name: 'VPres',
    component: VPres
  },

  {
    path: '/senatorial-election',
    name: 'Sen',
    component: Sen
  },

  {
    path: '/presidential-election/profile/:id',
    name: 'Profile',
    component: Profile,
    props: true,
  },

  {
    path: '/presidential-election/profile/:id/vote',
    name: 'formModal',
    component: formModal,
    props: true,
  },

  {
    path: '/presidential-election/profile/:id/email',
    name: 'emailModal',
    component: emailModal,
    props: true,
  },

  {
    path: '/presidential-election/profile/:id/email/verify',
    name: 'emailVerify',
    component: emailVerify,
    props: true,
  },

  {
    path: '/presidential-election/profile/:id/confirm',
    name: 'votedModal',
    component: votedModal,
    props: true,
  },

  {
    path: '/presidential-election/profile/:id/verify',
    name: 'verifyModal',
    component: verifyModal,
    props: true,
  },

  {
    path: '/presidential-election/profile/:id/resend',
    name: 'optionModal',
    component: optionModal,
    props: true,
  },

  {
    path: '/vice-presidential-election/profile/:id/vote',
    name: 'vpFormModal',
    component: vpFormModal,
    props: true,
  },

  {
    path: '/vice-presidential-election/profile/:id/email',
    name: 'vpEmailModal',
    component: vpEmailModal,
    props: true,
  },

  {
    path: '/vice-presidential-election/profile/:id/email/verify',
    name: 'vpEmailVerify',
    component: vpEmailVerify,
    props: true,
  },

  {
    path: '/vice-presidential-election/profile/:id/confirm',
    name: 'vpVotedModal',
    component: vpVotedModal,
    props: true,
  },

  {
    path: '/vice-presidential-election/profile/:id/verify',
    name: 'vpVerifyModal',
    component: vpVerifyModal,
    props: true,
  },

  {
    path: '/vice-presidential-election/profile/:id/resend',
    name: 'vpOptionModal',
    component: vpOptionModal,
    props: true,
  },

  {
    path: '/vice-presidential-election/profile/:id',
    name: 'VProfile',
    component: VProfile,
    props: true,
  },
  {
    path: '/senatorial-election/profile/:id/vote',
    name: 'snFormModal',
    component: snFormModal,
    props: true,
  },

  {
    path: '/senatorial-election/profile/:id/email',
    name: 'snEmailModal',
    component: snEmailModal,
    props: true,
  },

  {
    path: '/senatorial-election/profile/:id/email/verify',
    name: 'snEmailVerify',
    component: snEmailVerify,
    props: true,
  },

  {
    path: '/senatorial-election/profile/:id/confirm',
    name: 'snVotedModal',
    component: snVotedModal,
    props: true,
  },

  {
    path: '/senatorial-election/profile/:id/verify',
    name: 'snVerifyModal',
    component: snVerifyModal,
    props: true,
  },

  {
    path: '/senatorial-election/profile/:id/resend',
    name: 'snOptionModal',
    component: snOptionModal,
    props: true,
  },

  {
    path: '/senatorial-election/profile/:id',
    name: 'SProfile',
    component: SProfile,
    props: true,
  },

  {
    path: '/simulation/profile/president/:id',
    name: 'SimProfile',
    component: SimProfile,
    props: true,
  },

  {
    path: '/simulation/profile/vice-president/:id',
    name: 'SimVProfile',
    component: SimVProfile,
    props: true,
  },
  {
    path: '/presidential-election/confirm/:id',
    name: 'Confirm',
    component: afterVote,
    props: true,
  },

  {
    path: '/vice-presidential-election/confirm/:id',
    name: 'VConfirm',
    component: VafterVote,
    props: true,
  },

  {
    path: '/senatorial-election/confirm/:id',
    name: 'SConfirm',
    component: SafterVote,
    props: true,
  },


  {
    path: '/simulation/president/confirm/:id',
    name: 'SimConfirm',
    component: SimafterVote,
    props: true,
  },

  {
    path: '/simulation/vice-president/confirm/:id',
    name: 'SimVConfirm',
    component: SimVafterVote,
    props: true,
  },

  {
    path: '/news',
    name: 'NewsHome',
    component: NewsHome,
  },

  {
    path: '/12-year-old-filipino-student-develops-nft-based-voting-platform',
    name: 'ArticleOne',
    component: ArticleOne,
  },

  {
    path: '/filipino-student-teams-up-with-local-artist',
    name: 'ArticleTwo',
    component: ArticleTwo,
  },

  {
    path: '/register',
    name: 'Register',
    component: Register
  },

  // {
  //   path: '/presidential-election/politics',
  //   name: 'Politics',
  //   component: Politics
  // },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
