<template>
    <div>
        <div class="card mx-auto">
            <img class="card-img-top img-fluid" :src="imgUrl" alt="Card image cap">
            <div class="card-body">
                <h5 class="card-text">{{ name }}</h5>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ["name", "imgUrl"],
}
</script>

<style scoped>
  .card {
    width:30rem;
    height:40rem;
    background-image: url(../../assets/assets-10.png);
    background-size:cover;
    background-repeat:no-repeat;
    background-color: transparent !important;
    border:none;
    font-family:'PoppinsRegular', sans-serif;
    color:black;
    padding:30px;
  }

  img {
    border-radius:10px;
    height: 32rem;
    width:47rem;
    object-fit: contain;
  }

  .card-text {
      font-size: 2em;
  }

  a {
      text-decoration:none;
  }

  @media screen and (max-width: 1024px) {
        .body {
            padding: 10px 0;
            margin: 0;
        }

        .card {
            width:29rem;
            height:40rem;
        }
    }
    @media screen and (max-width: 420px) {
        .body {
            padding: 10px 0;
            margin: 0;
        }

        .card {
            width:16rem;
            height:22rem;
        }

        .btn {
            width:100%;
            margin-top:20px;
        }

        .card-text {
            font-size:1em;
        }
    }

    @media screen and (max-width: 320px) {

        .card {
            width:14rem;
            height:20rem;
        }
    }
</style>