<template>
  <div class="backdrop">
      <img src="../../../../assets/assets-01.jpg" alt="" class="bg">
    <div class="b-modal text-center">
      <div class="modalBody container-fluid">
          <div class="text container-fluid">
              <h4>Resend Code</h4> 
              <div class="d-flex flex-wrap justify-content-center">
                  <button class="btn btn-resend" @click="resend">Mobile Number</button>
                  <button class="btn btn-send" @click="sendEmail">Send via Email</button>
              </div> 
              <div class="mt-3">
                  <button class="btn btn-cancel" @click="cancel">Cancel</button>
              </div>
              <!-- <a href="#" style="text-decoration:none; color:black;"><button class="btn btn-send">Send via Email</button></a> -->
          </div>
        <!-- <button class="submit btn">OK</button>
        <button class="cancel btn">CANCEL</button> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },

  computed: {
      formData() {
          return this.$store.state.formData
      }
  },


   props: ["mobile"],
  data() {
      return {
          selectedCandidate:{},
          //txnHash:{},
          hashUrl: '',
          showEmail: false,
          showVerify: false,
          initData: {
              host: 'ivote.cloudchain.it'
          },
          usermobile: ''
      }
  },
  
    methods: {
        resend() {
            const params = {
                host:   this.initData.host,
                mobileNumber: this.usermobile,
                // captcha: this.captcha
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/vote/resend/otp',
                };
                this.axios(options)
                    .then((response) => {
                        // Success Code goes here
                        // console.log('success')
                        this.$router.push({ name: 'snVerifyModal', params: { id: this.$route.params.id}})
                    })
                    .catch((error) => {
                        // Error code goes here

                        console.log(error)
                        if(error.response.data.fieldErrors != undefined) {
                            this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        } else {
                            this.errorMsg = error.response.data.msgText
                        }
                        // console.log("this.errorMsg")
                    });
        },


        closeEmail() {
            this.showVerify = false
        },

        sendEmail() {
            this.$router.push({ name: 'snEmailModal', params: { id: this.$route.params.id}})
        },

        closeEmail() {
            this.showEmail = false
        },

         cancel() {
            this.$router.go(-1)
        },

        getCandidates() {
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/candidates/president',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                response.data.forEach((element, index) =>{
                    if(element.entityId == this.$route.params.id) {
                        this.selectedCandidate = element
                    }
                });
            })
            .catch((error) => {

            });
    },
    getMobile() {

       this.usermobile = localStorage.getItem("usermobile")
       /* const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/txn/recent/ivote?rowsPerPage=10',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                response.data.forEach((element, index) =>{
                    console.log(response.data)
                        this.selectedCandidate = element
                        this.hashUrl = 'https://explorer.cloudchain.it/#/transaction/' + element.txnHash
                });
            })
            .catch((error) => {

            });*/
    }
    },

    mounted() {
        this.getCandidates();
        this.getMobile();
    }
}
</script>

<style scoped>
.bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    border:none;
    object-fit: cover;
  }

.b-modal {
    width: 600px;
    height: auto;
    margin: 175px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 2;
    overflow-x: hidden !important;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    margin-top:200px;
    font-size:25px;
}

.modal-title{
    margin: 0 auto;
    color:black;
    font-family:'PoppinsSemiBold', sans-serif;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:20px;
    border-radius: 10px;
}

.submit, .cancel {
    background-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:200px;
    margin-right:10px;
}

.btn-resend {
    font-size:15px;
    margin-right:5px;
    font-family: 'PoppinsSemiBold', sans-serif;
    background-color:none;
    border-color:black;
    border-width:2px;
    color:black;
    width:45%;
    margin:5px;
}

.btn-send {
    font-size:15px;
    margin-left:5px;
    font-family: 'PoppinsSemiBold', sans-serif;
    background-color:none;
    border-color:black;
    border-width:2px;
    color:black;
    width:45%;
    margin:5px;
}

.btn-resend:hover, .btn-send:hover {
    background-color:orange;
    border-color:orange;
    border-width:2px;
    color:white;
}


.btn-cancel {
    font-size:15px;
    margin-right: 10px;
    font-family: 'PoppinsSemiBold', sans-serif;
    background-color:lightgray;
    color:black;
    width:50%;
}
@media screen and (max-width: 600px) {
        .txnHash {
            margin-left:5px;
        }
    }

@media screen and (max-width: 420px) {
        .b-modal {
            width: 410px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
            overflow: auto;
            top:0;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px;
            border-radius: 10px;
            font-size:15px;
        }
        .submit, .cancel {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            text-align:center;
            margin: 0 auto;
            margin-left:20px;
        }

        .text {
            font-size:15px;
            width:300px;
            text-align:center;
            margin: 0 auto;
        }

        .txnHash {
            margin-left:40px;
        }

        .btn-resend {
            font-size:15px;
            margin-left:5px;
            font-family: 'PoppinsSemiBold', sans-serif;
            color:black;
            width:100%;
        }

        .btn-send {
            font-size:15px;
            margin-right: 5px;
            font-family: 'PoppinsSemiBold', sans-serif;
            color:black;
            width:100%;
            margin-bottom:10px;
        }

        .btn-cancel {
            font-size:15px;
            margin-right: 5px;
            font-family: 'PoppinsSemiBold', sans-serif;
            color:black;
            width:100%;
            margin-bottom:10px;
        }
    }

    @media screen and (max-width: 380px) {
        .b-modal {
            width: 375px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 10px;
            border-radius: 10px;
        }
        .submit, .cancel {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-left:20px;
        }

        .txnHash {
            margin-left:35px;
        }
    }

    @media screen and (max-width: 320px) {
        .b-modal {
            width: 320px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px;
            border-radius: 10px;
        }
        .submit, .cancel {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-left:20px;
        }

        .txnHash {
            margin-left:35px;
        }
    }
</style>