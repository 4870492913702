<template>
  <div>
    <splash-header :showPublicus="showPublicus" :showRegister="showRegister"></splash-header>
  <div class="body text-center" >
    <img src="../../../assets/assets-01.jpg" alt="" class="bg">
    <div class="publicus-image">
      <div class="d-flex justify-content-around">
        <div></div>
        <div class="text-center imageWrapper mt-5">
          <router-link to="/"><img src="../../../assets/assets-06.png" class="logoImg"></router-link>
          <img src="../../../assets/publicus-logo.png" class="publicusImg">
          <router-link to="/register"><button class="btn btn-send">REGISTER</button></router-link>
        </div>
      </div>
    </div>
    <div class="publicus-image-mobile">
      <div class="publicus-banner"></div>
      <div class="d-flex justify-content-center">
        <div></div>
        <div class="text-center imageWrapper justify-content-center mt-5">
          <router-link to="/"><img src="../../../assets/assets-06.png" class="logoImg" style="width:10rem;height:auto;"></router-link>
          <img src="../../../assets/publicus-logo.png" class="publicusImg">
          <router-link to="/register"><button class="btn btn-send">REGISTER</button></router-link>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap justify-content-around mt-5">
      <div>
        <h3>Presidential Results</h3>
        <table class="container-fluid text-center chartCon table-responsive" width="100" height="100">
          <canvas id="presChart"></canvas>
        </table>
      </div>
      <div>
        <h3>Vice Presidential Results</h3>
        <table class="container-fluid text-center chartCon table-responsive" width="100" height="100">
          <canvas id="vpChart"></canvas>
        </table>
      </div>
    </div>
    <!--Modals-->
  </div>
  <Footer></Footer>
  </div>
</template>

<script>
// import CreateAccount from './modals/signup/Create.vue'
import Footer from '../../../components/Footer.vue'
import Howto from '../../../components/modal/howto/One.vue'
import SplashHeader from '../../../components/splashHeader.vue'
import Chart from 'chart.js/auto'

export default {
  components: {
    //   CreateAccount,
      Footer,
      Howto,
      SplashHeader,
    },
    data: function() {
        return {
            showPublicus: true,
            showRegister: true,
        }
    },
    methods: {
      toggleCreate() {
          this.showCreate = !this.showCreate
      },

      openHowto() {
        this.showHowto = true
      },

      closeHowto() {
        this.showHowto = false 
      },

      hidePublicus() {
        this.showPublicus = false
      },

      presChart() {
      const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/candidates/president?type=simulation',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                let candidateArray = [];
                    let voteCountArray = [];
                    response.data.forEach(function(data) {
                        candidateArray.push(data.name)
                        voteCountArray.push(data.voteCount)
                    });
                    
                        let fchart = document.getElementById("presChart").getContext('2d');

                          let fiat = new Chart(fchart, {
                            type:'bar', //line chart
                            data:{
                                labels:candidateArray,
                                datasets: [{
                                    label: 'Votes',
                                    data:voteCountArray,
                                    backgroundColor: [
                                      'rgb(255, 94, 0)',
                                      // 'rgba(255, 159, 64, 0.2)',
                                      // 'rgba(255, 205, 86, 0.2)',
                                      // 'rgba(75, 192, 192, 0.2)',
                                      // 'rgba(54, 162, 235, 0.2)',
                                      // 'rgba(153, 102, 255, 0.2)',
                                      // 'rgba(201, 203, 207, 0.2)'
                                    ],
                                    borderColor: [
                                      'rgb(255, 159, 64)',
                                      // 'rgb(255, 159, 64)',
                                      // 'rgb(255, 205, 86)',
                                      // 'rgb(75, 192, 192)',
                                      // 'rgb(54, 162, 235)',
                                      // 'rgb(153, 102, 255)',
                                      // 'rgb(201, 203, 207)'
                                    ],
                                    borderWidth: 1,
                                }]
                            },

                            options: {
                              maintainAspectRatio: false,
                              tooltips: {
                                displayColors: false,
                                callbacks: {
                                  
                                }
                              }

                                // plugins: {
                                //   tooltip: {
                                //     callback: function (value, index, values) {
                                //       return value + '%'
                                //     }

                                //   }
                                // }
                              
                          }
                          });
            })
            .catch((error) => {

            });
      },
      vpChart() {
        const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/pub/candidates/vpresident?type=simulation',
          };
          this.axios(options)
              .then((response) => {
                  // Success Code goes here
                  let candidateArray = [];
                      let voteCountArray = [];
                      response.data.forEach(function(data) {
                          candidateArray.push(data.name)
                          voteCountArray.push(data.voteCount)
                      });
                      
                          let fchart = document.getElementById("vpChart").getContext('2d');

                            let fiat = new Chart(fchart, {
                              type:'bar', //line chart
                              data:{
                                  labels:candidateArray,
                                  datasets: [{
                                      label: 'Votes',
                                      data:voteCountArray,
                                      backgroundColor: [
                                        'rgb(255, 94, 0)',
                                        // 'rgba(255, 159, 64, 0.2)',
                                        // 'rgba(255, 205, 86, 0.2)',
                                        // 'rgba(75, 192, 192, 0.2)',
                                        // 'rgba(54, 162, 235, 0.2)',
                                        // 'rgba(153, 102, 255, 0.2)',
                                        // 'rgba(201, 203, 207, 0.2)'
                                      ],
                                      borderColor: [
                                        'rgb(255, 159, 64)',
                                        // 'rgb(255, 159, 64)',
                                        // 'rgb(255, 205, 86)',
                                        // 'rgb(75, 192, 192)',
                                        // 'rgb(54, 162, 235)',
                                        // 'rgb(153, 102, 255)',
                                        // 'rgb(201, 203, 207)'
                                      ],
                                      borderWidth: 1,
                                  }]
                              },

                              options: {
                                maintainAspectRatio: false,
                                tooltips: {
                                  displayColors: false,
                                  callbacks: {
                                    
                                  }
                                }

                                  // plugins: {
                                  //   tooltip: {
                                  //     callback: function (value, index, values) {
                                  //       return value + '%'
                                  //     }

                                  //   }
                                  // }
                                
                            }
                            });
              })
              .catch((error) => {

              });
      },
    },

    mounted() {
      this.presChart();
      this.vpChart();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .body {
    background-size:cover;
    height:100vh;
    color:white;
    padding:10px;
    background-repeat:no-repeat;
    margin: 0 auto;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    border:none;
    object-fit: cover;
  }


  .publicus-image{
    width:100%;
    height:65vh;
    border-radius: 20px;
    background-image: url(../../../assets/publicus-banner.jpg);
    background-size: cover;
    padding:100px;
}

.imageWrapper {
    width:35%;
  }

  .logoImg {
    width:10rem;
    height:auto;
  }
.publicusImg {
  width:30rem;
  height:auto;
}


.btn {
    background-color:black;
    border-width:2px;
    color:white;
    font-family: 'PoppinsMedium', sans-serif;
    width:100%;
}

.btn:hover {
    background-color:rgb(56, 56, 56);
    border-color:rgb(56, 56, 56);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
  }

  .chartCon{
      position: relative;
      height: 35vh;
      width: 45vw;
      background-color:black;
      border-radius:10px;
  }

  #candidateChart {
    background-color:black;
    border-radius:10px;
  }

  .publicus-image-mobile {
    padding:100px;
    display:none;
  }

  .publicus-banner{
        width:100%;
        height:30vh;
        border-radius: 20px;
        background-image: url(../../../assets/publicus-banner.jpg);
        background-size: cover;
        padding:100px;
    }



  /* .newsCon img:hover {
    box-shadow: 2.5px 5px 20px black ;
  } */

  @media screen and (min-width:1920px) {
    .chartCon{
      display: flex;
      height: 30vh;
      width: 45vw;
    }
  }

  

   @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }

      .newsCon {
        padding:10px;
        margin:50px 0px;
      }

    .newsCon img {
      border-radius:10px;
      width:80%;
    }

    .card{
        display: flex;
        justify-content: center !important;
      }

      .cardRow {
        width:100%;
      }

      .smallCard {
        width:14rem;
        height:20rem;
        background-image: url(../../../assets/assets-10.png);
        background-size:cover;
        background-repeat:no-repeat;
        background-color: transparent !important;
        border:none;
        font-family:'PoppinsRegular', sans-serif;
        color:black;
        padding:20px;
        font-size:12px;
      }

      .signTwo {
        width:600px;
        justify-content:center;
        text-align: center;
      }

      .voteImg {
        width:175px;
      }



      .publicus-image{
          width:100%;
          height:65vh;
          border-radius: 20px;
          background-image: url(../../../assets/publicus-banner.jpg);
          background-size: cover;
          padding:100px;
          display:none;
      }

      .imageWrapper {
        width:40%;
        justify-content:space-around;
      }

          
    }

    @media screen and (max-width:1024px) {
    .chartCon{
      display: flex;
      height: 30vh;
      width: 100vw;
    }

    .publicus-image-mobile{
        display:block;
    }

    .imageWrapper {
        width:40%;
        justify-content:space-around;
      }

      .logoImg {
        width:5rem;
        height:auto;
      }
    .publicusImg {
      width:20rem;
      height:auto;
    }

  
  }

  @media screen and (max-width:800px) {
     .chartCon{
      display: flex;
      height: 30vh;
      width: 100vw;
    }

    .imageWrapper {
        width:100%;
      }

      .logoImg {
        width:10rem;
        height:auto;
      }
    .publicusImg {
      width:25rem;
      height:auto;
    }
  }


   @media screen and (max-width: 769px) {
     .body {
        height:auto;
        margin: 0;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card, .smallCard {
      width:15rem;
      height:21rem;
      font-size:10px;
    }

    .newsCon {
        padding:10px;
        margin:25px 0px;
      }

    .newsCon img {
      border-radius:10px;
      width:100%;
    }

    .cardRow, .bigCon, .firstThree {
            margin:0 auto;
            padding:5px;
            width:100% !important;
            margin-bottom:50px;
            display:block !important;
            flex-wrap:nowrap !important;
          }
    
        
  }

    @media screen and (max-width: 600px) {
      .body {
        padding:20px;
      }
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card, .smallCard {
            width:11rem;
            height:15rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }

          .simul {
            background-color:rgb(255, 102, 0);
            border-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            margin-right:2px;
            border-width: 2px;
            width:80% !important;
          }

          .explore {
            background-color:rgb(255, 102, 0);
            border-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            margin-right:2px;
            border-width: 2px;
            width:100% !important;
          }

          .howto {
            border-width:2px;
            border-color:white;
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:80% !important;
          }

          .cardRow {
            margin:0px;
            padding:0px !important;
            width:100% !important;
            margin-bottom:50px;
          }
          
          .imageWrapper {
            width:100%;
          }

          .logoImg {
            width:10rem;
            height:auto;
          }
        .publicusImg {
          width:20rem;
          height:auto;
        }
    }

    @media screen and (max-width: 430px) {

       .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:7rem;
            height:9.5rem;
            padding-bottom: 9px;
          }
          .card h6{
            font-size: 8px !important;
          }
          .heading {
            font-size:15px;
          }

          .subheading {
            font-size:9px;
          }

          .explore {
            background-color:rgb(255, 102, 0);
            border-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            margin-right:2px;
            border-width: 2px;
            width:30vh !important;
          }

          .headers {
            justify-content:center;
          }

          .cardRow {
            margin:0px;
            padding:0px !important;
            width:100% !important;

            margin-bottom:50px;
          }

          .signTwo {
            width:400px;
            justify-content:center;
            text-align: center;
          }

          .voteImg {
            width:150px;
          }

          .imageWrapper {
            width:100%;
          }

          .logoImg {
            width:10rem;
            height:auto;
          }
          .publicusImg {
            width:15rem;
            height:auto;
          }

          .publicus-image-mobile {
            padding:10px;
          }
    }

    @media screen and (max-width: 380px) {
      .body{
        margin: 0 auto !important;
      }
       .firstThree {
             margin: 0 auto !important;
             display: flex;
             justify-content: center;
             padding:0;
        }
         .card, .smallCard {
            width:6.4rem;
            height:8.5rem;
          }
          .card img{
            height:7.5rem !important;
          }
          .card h6{
            font-size: 6px !important;
          }
          .heading {
            font-size:15px;
          }

          .subheading {
            font-size:9px;
          }

          .socCon #socImg {
            margin:10px;
            margin-top:40px;
            width:30px;
          }

          .cardRow {
            margin:0px;
            padding:0px !important;
            width:100% !important;
            margin-bottom:50px;
          }
    }

    @media screen and (max-width: 320px){
          .card, .smallCard {
            width:5.3rem;
            height:7rem;
          }
          .card img{
            height:7rem !important;
          }

          .cardRow {
            margin:0;
            padding:0;
            margin-bottom:50px;
          }

          .voteImg {
            width:125px;
          }
    }


  /* @media screen and (max-width:800px) {
    .body {
      background-image: url(../assets/mobile/bg-11.jpg);
      background-size:cover;
      height:auto;
      color:white;
      padding:25px;
      background-repeat:no-repeat;
    }

    .logoImg {
      width:50%;
    }

    .left-column {
      padding:50px;
    }

    .heading {
      font-size:25px;
    }

    .subheading {
      font-size:12px;
    }

    button {
      margin-top:5px;
      width:50% !important;
    }
  }

  @media screen and (max-width: 320px) {
        .body {
            background-image: url(../assets/assets-01.jpg);
            background-size:cover;
            height:auto;
            color:white;
            background-repeat:no-repeat;
            padding: 0;
            margin: 0;
        }

        #heading {
            font-size:20px;
        }

        #subHeading {
            font-size:15px;
        }

        .card {
          margin-left:25px;
        }
    } */
</style>
