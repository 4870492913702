<template>
  <div class="backdrop">
    <div class="b-modal">
      <div class="modalBody container-fluid">          
          <div class="content text-center">
                <h5 class="heading">How to iVote</h5>
                <h5 class="text-white number">3</h5>
                <p class="mt-2">Enter mobile number, click box and ”ok”</p>
                <img class="img-fluid" src="../../../assets/web-images/howto-09.png" alt="">
                <div class="circleCon d-flex flex-wrap text-center justify-content-center">
                    <div class="circles"></div>
                    <div class="circles"></div>
                    <div class="circles" id="active"></div>
                    <div class="circles"></div>
                    <div class="circles">h</div>
                </div>
                <div class="buttonCon d-flex flex-wrap justify-content-center mt-3">
                    <button class="btn" @click="closeModal">GO BACK</button>
                    <button class="btn" @click="next">NEXT</button>
                </div>
          </div>
      </div>
    </div>
    <Four v-if="showFour" @close="modalClose"></Four>
  </div>
</template>

<script>
import Four from './Four.vue'

export default {

  components: {
      Four,
  },

  data() {
      return {
          showFour: false,
      }
  },
  
    methods: {
        closeModal() {
            this.$emit("close")
        },

        next() {
            this.showFour = true
        },

        modalClose() {
            this.showFour = false
        }
    },

    mounted() {

    }
}
</script>

<style scoped>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}
.b-modal {
    width: 620px;
    height: auto;
    margin: 175px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 2;
    overflow-x: hidden !important;
    color:black;
    font-family:'PoppinsRegular', sans-serif;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    margin-top:200px;
    font-size:25px;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:20px;
    border-radius: 10px;
}

.heading {
    font-family:'PoppinsSemiBold', sans-serif;
}

.text-right {
    color:black;
    cursor:pointer;
}

.number {
    background-color:rgb(255, 120, 0);
    width:27px;
    border-radius:14px;
    padding:2px;
    margin: 0 auto !important;
    font-family: 'PoppinsMedium', sans-serif
}

.circleCon {
    margin:0 auto !important;
}
.circles {
    background-color:gray;
    width:10px;
    height:10px;
    margin:5px;
    border-radius:11px;
    color:gray;
    font-size:1px;
}

#active {
    background-color:rgb(255, 120, 0);
    color:rgb(255, 120, 0);
}

.btn {
    border-color:gray;
    border-width:2px;
    color:gray;
    font-family: 'PoppinsMedium', sans-serif;
    width:30%;
}

.btn:hover {
    background-color:rgb(255, 102, 0);
    border-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    margin-right:2px;
  }

@media screen and (max-width: 600px) {
        .b-modal {
            width: 600px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
            overflow: auto;
            top:0;
        }
    }

@media screen and (max-width: 420px) {
        .b-modal {
            width: 414px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
            overflow: auto;
            top:0;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px;
            border-radius: 10px;
            font-size:15px;
        }
    }

    @media screen and (max-width: 380px) {
        .b-modal {
            width: 375px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 10px;
            border-radius: 10px;
        }
    }

    @media screen and (max-width: 320px) {
        .b-modal {
            width: 320px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px;
            border-radius: 10px;
        }

        .btn {
            border-color:gray;
            border-width:2px;
            color:gray;
            font-family: 'PoppinsMedium', sans-serif;
            width:40%;
        }
    }
</style>