<template>
        <div>
            <Header :isNewsActive="isNewsActive"></Header>
            <div class="body">
                <img src="../../../../assets/assets-01.jpg" alt="" class="bg">
                <h4><router-link style="text-decoration: none; font-weight:bold; color:white;" to="/news">← Go Back</router-link></h4>
                <div class="container-fluid news-body" id="news-one">
                    <div class="text-center">
                        <h1 id="news-heading">12-Year Old Filipino Student Develops NFT-based Voting Platform</h1>
                    </div>
                    <div id="news-image" class="text-center">
                        <img src="../../../../assets/news-image-01.png" class="img-fluid news-img" alt="">
                    </div>
                    <div id="news-text">
                        <p id="date">October 27, 2021</p>
                        <p id="paragraph">Blockchain projects keep on exploring new ways to disrupt the world — from finance to real estate, to food supply chains, to art, and most recently, voting.</p>
                        <p id="paragraph">Countries like the United States, Russia, Thailand, and South Korea have already utilized blockchain technologies to conduct voting processes for their citizens as the exercise is an important part of any democratic process.</p>
                        <p id="paragraph">Could this become a possibility in the Philippines?</p>
                        <p id="paragraph">Eighth-grader Rien Lewis Pecson (12 years old) and Neil Fernandez, Jr. teamed up to make non-fungible token (NFT) blockchain voting a reality in the Philippines. Pecson has taken a keen interest in software development and the world of blockchain, while Fernandez is a Filipino artist and graphics designer whose portfolio includes work for multiple tech startups both here and abroad. Together, they created iVote.ph — the world’s first blockchain-based NFT voting platform powered by the Cloudchain blockchain.</p>
                        <p id="paragraph">As the public’s interest intensifies for the upcoming Presidential Election in 2022, iVote was timed perfectly to run a poll on the candidates featured as NFT Art by Neil. The befitting pilot aims to assess the views of Filipinos in today’s political climate.</p>
                        <p id="paragraph">iVote.ph allows citizens to cast their votes where their digital identity is secured and verified by non-fungible tokens or NFT. A non-fungible token is “a unique and non-interchangeable unit of data stored on a digital ledger.” NFTs are hard to replace or replicate, thereby creating validity and value due to their scarcity.</p>
                        <p id="paragraph">The website will not be strictly exclusive to political polls but is designed to be a voting platform of sorts — for sports, entertainment, art, and other areas or industries. In concept, iVote.ph brings together blockchain and democracy to allow people to vote directly on trending current events and issues from anywhere in the world.</p>
                        <p id="paragraph">“I wanted to create something that will give people a better way to vote about things they care for, something unbiased and nonpartisan, where anyone can easily use it, without technical or blockchain knowledge. Even easy enough for my grandmother,” according to Pecson.</p>
                        <p id="paragraph">When one signs up on iVote.ph with their mobile number, voters are credited with unique and newly minted voting tokens for free to cast votes on an NFT art or asset. Once a voter submits their selections, the blockchain nodes verify the validity of each vote and add it to the growing blockchain. Made to hopefully avoid skepticism about surveys, the poll results, being on the blockchain, are real-time, transparent, and cannot be altered.</p>
                        <p id="paragraph">By next year, the duo of Rien and Neil aims to deploy a “Citizen Verification System” (CVS) within the platform or what financial institutions and businesses refer to as “Know Your Customer” (KYC). By adding a registration process with valid identification requirements, as well as voter credentials or certifications, Rien and Neil strongly believe that blockchain voting will increase trust in election systems.</p>
                        <p id="paragraph">Combined with the expansion of societal comfort levels toward digital interactions and the growing global interest in blockchain developments, it’s not surprising to think that non-fungible tokens could play a major role in society.</p>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
</template>

<script>
import Header from '../../../../components/Header.vue'
import Footer from '../../../../components/Footer.vue'

export default {
    components: {
        Header,
        Footer,
    },

    data() {
        return {
            isNewsActive: true,
        }
    }

}
</script>

<style scoped>
    .body {
        background-size:cover;
        height:100vh;
        color:black;
        background-repeat:no-repeat;
        margin: 0 auto;
        padding:20px;
    }

    .bg {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -9999;
        border:none;
        object-fit: cover;
    }

    .news-img {
        width:50rem;
        border-radius:5px;
    }

    .news-body {
        background-color:rgba(128, 128, 128, 0.2);
        border-radius:10px;
        padding:10px;
        border-color:rgb(255, 102, 0);
        border-width:15px;
    }

    #news-image {
        width:100%;
    }

    #news-text {
        width:100%;
        padding:10px;
        text-align: justify;
    }

    #news-heading {
        font-family:'PoppinsSemiBold', sans-serif;
        font-size:40px;
    }

    #news-paragraph {
        font-family:'PoppinsRegular', sans-serif;
    }

    #see-more {
        color:black;
        text-decoration:none;
        background-color:rgb(255, 102, 0);
        font-family:'PoppinsMedium', sans-serif;
    }

    #see-more:hover {
        color:white;
        text-decoration:none;
        background-color:rgb(255, 125, 0);
        font-family:'PoppinsMedium', sans-serif;
    }

    #date {
        font-family:'PoppinsRegular', sans-serif;
        font-size:12px;
    }

    @media screen and (max-width: 1024px) {
        #news-heading {
            font-family:'PoppinsSemiBold', sans-serif;
            font-size:40px;
        }

        #news-overview {
            font-family:'PoppinsRegular', sans-serif;
            font-size:12px;
        }
    }

    @media screen and (max-width: 768px) {
        #news-image {
            width:100%;
        }

        #news-text {
            width:100%;
            padding:10px;
            text-align:justify;
        }

        .news-body {
            display:block;
        }

        #news-heading {
            font-family:'PoppinsSemiBold', sans-serif;
            font-size:30px;
        }
    }

    @media screen and (max-width: 320px) {
        .body {
            padding-bottom:700px;
        }

        #news-heading {
            font-size:20px;
        }
    }
</style>