<template>

    <div>
        <splash-header :showPublicus="showPublicus"></splash-header>
        <div class="body">
            <img src="../assets/assets-01.jpg" alt="" class="bg">
            <div class="card container">
                <form class="form-inline form web">
                    <div class="mt-5">
                        <p class="mt-2">Already registered?
                        <button type="submit" @click.prevent="login" class="btn btn-secondary log-in">LOGIN</button>
                        </p>
                    </div>
            <div class="text-center">
                <p class="text-red">{{ errorMsg }}</p>
            </div>
                <div class="row">
                    <div class="form-group col">
                        <label for="inputEmail">Email Address</label>
                        <input type="email" class="form-control" v-model="emailAddress" id="inputEmail" placeholder="Email">
                        <p class="danger" v-if="errors.emailAddress != '' ">{{errors.emailAddress}}</p>
                    </div>
                
                    <div class="form-group col">
                        <label for="inputMobile">Mobile Number</label>
                        <!--<input type="text" class="form-control" id="inputMobile" placeholder=""> -->
                         <div class="flex-wrap d-flex mobileWrapper">
                            <div class="input-group-prepend code d-flex"   v-on:click="toggleCountryList" style="cursor:pointer;">
                                <button class="flag-btn" type="button">
                                    <img class="default-flag" :src="require('../../public/assets/signup_flags/' + country + '.png' )">
                                </button>
                                <button class="icon"><b-icon icon="caret-down-fill"></b-icon></button>
                                
                                <h6 class="mt-2 text-dark" style="background-color:#efefef;">+{{ areaCode }}</h6>
                            </div>
                            <div class="country-list" v-if="showCountry">
                                <div class="row">
                                <div class="col-sm-6" v-for="code in mobileCodes" v-on:click="selectCountry(code)" :key="code.id">
                                <div >
                                <img class="flag-list-img" :src="code.imgPath">
                                </div>
    
                                <div class="country-name"> <span> {{ code.country }} </span> </div>
                                
                                </div>
                                
                                </div>
                            </div>
                            
                        <div class="col">
                            <input type="text" v-model="mobileNumber" class="form-control">
                        </div>
                    </div>
                    <p class="danger" v-if="errors.mobileNumber != '' ">{{errors.mobileNumber}}</p>
                        
                    </div>
                </div>
            </form>
            <form class="form-inline form mobile">
                <div class="row">
                    <div class="form-group col">
                        <label for="inputEmail">Email address</label>
                        <input type="email" class="form-control" v-model="emailAddress" id="inputEmail" placeholder="Email">
                    </div>
                    <p class="danger" v-if="errors.emailAddress != '' ">{{errors.emailAddress}}</p>
                </div>
                <div class="row">
                    <div class="form-group col">
                        <label for="inputMobile">Mobile Number</label>
                        <!--<input type="text" class="form-control" id="inputMobile" placeholder=""> -->
                         <div class="flex-wrap d-flex mobileWrapper">
                            <div class="input-group-prepend code d-flex"  v-on:click="toggleCountryList">
                                <button class="flag-btn" type="button">
                                    <!-- <img class="default-flag" :src="require('../../public/assets/signup_flags/' + country + '.png' )"> -->
                               
                               <span class="icon"><b-icon icon="caret-down-fill"></b-icon></span></button>
                                
                                <h6 class="mt-2 text-dark" style="background-color:#efefef;">+{{ areaCode }}</h6>
                            </div>
                            <div class="country-list" v-if="showCountry">
                                <div class="row">
                                <div class="col-sm-6" v-for="code in mobileCodes" v-on:click="selectCountry(code)" :key="code.id">
                                <div >
                                <img class="flag-list-img" :src="code.imgPath">
                                </div>

                                <div class="country-name"> <span> {{ code.country }} </span> </div>
                                
                                </div>
                                
                                </div>
                            </div>
                            
                        <div class="col">
                            <input type="text" v-model="mobileNumber" class="form-control">
                        </div>
                    </div>
                    <p class="danger" v-if="errors.mobileNumber != '' ">{{errors.mobileNumber}}</p>
                        
                    </div>
                </div>
            </form>
            </div>
            <div class="card container">
                <form class="form-inline">
                <div class="row">
                    <div class="col">
                        <label for="inputFirstName">First Name</label>
                        <input type="text" class="form-control" v-model="firstName" id="inputFirstName" placeholder="First name">
                        <p class="danger" v-if="errors.firstName != '' ">{{errors.firstName}}</p>
                    </div>
                     
                    <div class="col">
                        <label for="inputLastName">Last Name</label>
                        <input type="text" class="form-control" v-model="lastName" id="inputLastName" placeholder="Last name">
                    <p class="danger" v-if="errors.lastName != '' ">{{errors.lastName}}</p>
                    </div>
                     
                </div>
                

                <div class="row pt-3">
                    <div class="col">
                        <label for="example-datepicker">Birthdate</label>
                         <b-input-group class="mb-3">
                        <date-picker v-model="birthDate"  valueType="format" format="MM/DD/YYYY"></date-picker>
                        </b-input-group>
                        <p class="danger" v-if="errors.birthDate != '' ">{{errors.birthDate}}</p>
                    </div>
                    
                    <div class="col">
                        <b-form-group class="label" label="Gender" v-slot="{ ariaDescribedby }">
                            <div class="form-row d-flex justify-content-between" >
                            <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="male" value="Male">Male</b-form-radio>
                            <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="female" value="Female">Female</b-form-radio>
                            <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="others" value="Others">Others</b-form-radio>
                            </div>
                        </b-form-group>
                        <p class="danger" v-if="errors.gender != '' ">{{errors.gender}}</p>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col">
                        <label for="inputAdd">Address</label>
                        <input type="text" class="form-control" id="inputAdd">
                    </div>
                    <div class="col">
                        <label for="inputCity">City</label>
                        <input type="text" v-model="city" class="form-control" id="inputCity">
                        <p class="danger" v-if="errors.city != '' ">{{errors.city}}</p>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col">
                        <label for="inputProv">Province</label>
                        <input id="inputProv" v-model="province" class="form-control">
                        <p class="danger" v-if="errors.province != '' ">{{errors.province}}</p>
                            
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">Country</label>
                            <select class="form-control" id="exampleFormControlSelect1" v-model="countryName" @change="getCountryCode">
                              <option v-for="code in mobileCodes" :key="code.id">{{ code.country }}</option>
                            </select>
                          </div>
                    </div>
                </div>

                <div class="checkboxGroup checkWrapper container-fluid mt-5">
                    <input type="checkbox" class="form-check-input mr-3" id="agree" v-model="selectedOne" @click="verifySelected()" required>
                    <label class="checkLabel form-check-label" for="agree">I understand that my iVote will be entered into the blockchain.</label>
                    
                </div>

                <div class="checkboxGroup checkWrapper container-fluid justify-content-start checkboxGroupTwo">
                    <input type="checkbox" class="form-check-input mr-3" id="agree" v-model="selectedTwo" @click="verifySelected()" required>
                    <label class="checkLabel form-check-label" for="agree">I agree to the <a href="https://cloudchain.ai/terms-of-service.html" target="_blank" style="text-decoration:none; color:rgb(0, 0, 0);">Terms of Service</a> and <a href="https://cloudchain.ai/privacy-policy.html" target="_blank" style="text-decoration:none; color:rgb(0, 0, 0);">Privacy Policy</a>.</label>
                    
                </div> 
                <div class="">
                    <p class="text-red">{{ notSelected }}</p>
                </div>
            </form>
            <form class="form-inline">
                <button type="submit" @click.prevent="register" class="btn btn-primary mt-5 ok">REGISTER</button>
                <br/>
            </form>
         </div>
        </div>
        <Login v-if="showLogin" @close="closeLogin"></Login>
        <Footer></Footer>
        <registerVerify v-if="showRegVer" @close="closeVerify" :mobile="areaCode + mobileNumber"></registerVerify>
    </div>
</template>

<script>

import SplashHeader from '../components/splashHeader.vue';
import Login from '../components/Login.vue';
import Footer from '../components/Footer.vue';
import moment from 'moment';
import DateDropdown from 'vue-date-dropdown';
import VueSelectImage from 'vue-select-image';
import registerVerify from './simulation/modal/verifyModal.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// add stylesheet
require('vue-select-image/dist/vue-select-image.css');


export default {
    name:'Register',
    components: {
        Login,
        Footer,
        SplashHeader,
        VueSelectImage,
        DateDropdown,
        registerVerify,
        DatePicker
    },
    data: function(){
        return {
            selectedMobile: {},
            showCountry: false,
            country: 'PH',
            areaCode: '63',
            mobileNumber: '',
            emailAddress:'',
            firstName:'',
            lastName:'',
            gender:'',
            birthDate:'03/12/1999',
            newDateOfBirth:'',
            months:'',
            city:'',
            province:'',
            countryName:'Philippines',
            signupHost: 'ivote2022.cloudchain.it',
            selectedOne: '',
            selectedTwo: '',
            notSelected:'',
            errors: {
                emailAddress:'',
                mobileNumber:'',
                firstName:'',
                lastName:'',
                gender:'',
                birthDate:'',
                city:'',
                province:'',
            },
            errorMsg:'',
            showLogin: false,
            showRegVer: false,
            showPublicus: true,
        }
    },

    computed: {
        mobileCodes() {
            return this.$store.state.mobileCodes;
        }
    },

    mounted() {
      this.$store.dispatch("mobileCodes");
    },

    beforeMount() {
        this.changeFlag();
    },
    watch: {
        'signupData.loginId': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.errorMsg = ''
            }
        },
        'signupData.loginPass': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.errorMsg = ''
            }
        },

        'areaCode': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.mobileNumber = ''
            }
        },
        birthDate(){
            this.newDateOfBirth = moment(this.birthDate, "MM/DD/YYYY").format("MM/DD/YYYY"); 
        }
    },
    methods: {
        closeLogin() {
            this.showLogin = false
        },

        closeVerify() {
            this.showRegVer = false
        },
        login(){
            this.showLogin = !this.showLogin;
        },
        changeFlag() {
            this.axios
                .get("/assets/mobilecodes.json")
                .then((response) => {
                    let mobileCodes = response.data;
                    mobileCodes.forEach((element, index) => {
                        element.imgPath = '/assets/signup_flags/' + element.countryCode + '.png'
                    if(element.mobileCode == this.areaCode) {
                            this.selectedMobile = element
                            console.log(this.selectedMobile)
                            // console.log(this.imgPath)
                            // console.log('working')
                        }
                    }) 
                    console.log(this.selectedMobile)
                })
                .catch((error) => {
                    // console.log(error);
                });
        },

        selectCountry: function(c) {
            this.showCountry = false;
            this.country = c.countryCode;
            this.areaCode = c.mobileCode
        },
        toggleCountryList: function(){
            this.showCountry = !this.showCountry;
            console.log('jshdk')
        },
        verifySelected() {
            if (this.selectedOne == true && this.selectedTwo == true) {
                this.notSelected = ''
            } 
        },

        register(){
            let hasError = false;
                if ( this.emailAddress == "" || this.emailAddress == undefined || this.emailAddress == null){
                    hasError = true;
                    this.errors.emailAddress = "This is required."
                }

                if ( this.mobileNumber == "" || this.mobileNumber == undefined || this.mobileNumber == null){
                    hasError = true;
                    this.errors.mobileNumber = "This is required."
                }

                if ( this.firstName == "" || this.firstName== undefined || this.firstName == null){
                    hasError = true;
                    this.errors.firstName = "This is required."
                }
                if ( this.lastName == "" || this.lastName == undefined || this.lastName == null){
                    hasError = true;
                    this.errors.lastName = "This is required."
                }
                if ( this.birthDate == "" || this.birthDate == undefined || this.birthDate == null){
                    hasError = true;
                    this.errors.newDateOfBirth = "This is required."
                }
                if ( this.gender == "" || this.gender == undefined || this.gender == null){
                    hasError = true;
                    this.errors.gender = "This is required."
                }
                if ( this.city == "" || this.city == undefined || this.city == null){
                    hasError = true;
                    this.errors.city = "This is required."
                }
                if ( this.province == "" || this.province == undefined || this.province == null){
                    hasError = true;
                    this.errors.province = "This is required."
                }
                
                console.log(hasError)

                if (!hasError ){
                // console.log('send code button click')
                const params = {
                    signupHost:   this.signupHost,
                    email: this.emailAddress,
                    loginId: this.areaCode + this.mobileNumber,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    bdate: this.birthDate,
                    gender: this.gender.substring(0, 1),
                    city: this.city,
                    province: this.province,
                    country: this.country,
                }
                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data,
                    url: '/cx/vote/register',
                };
                this.axios(options)
                    .then((response) => {
                        // Success Code goes here
                       
                        console.log(response)
                        this.showRegVer = true
                        

                    })
                    .catch((error) => {
                        // Error code goes here
                        // this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        if(error.response.data.fieldErrors != undefined) {
                            this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        }  else {
                            this.errorMsg = error.response.data.msgText
                        }
                    });
                } else if((this.selectedOne == false) || (this.selectedTwo == false)) {
                    this.notSelected = 'Please agree to all terms and conditions.'
                }
        },

        getCountryCode() {
            console.log('...')
            this.mobileCodes.forEach((value, index) => {
                if(this.countryName == value.country) {
                    this.country = value.countryCode
                    this.areaCode = value.mobileCode
                    console.log(this.country)
                }
            })
        }






    }
}
</script>

<style scoped>
.body {
    background-size:cover;
    height:100vh;
    color:white;
    padding:10px;
    background-repeat:no-repeat;
    margin: 0 auto !important;
  }
  .mobile{
      display: none;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    border:none;
    object-fit: cover;
  }
  .card {
        background-color:rgba(50,128,128,0.1);
        display: flex;
        justify-content: center;
        border:white;
        font-family:'PoppinsRegular', sans-serif;
        color:white;
        padding-bottom:50px;
        margin: 0 auto !important;
  }
  .form{
      width: 50%;
  }
  
  .gender{
      display: inline-flex;
      justify-content: space-evenly;
  }
  .ok {
        background-color:rgb(255, 102, 0);
        color:white;
        font-family:'PoppinsMedium', sans-serif;
        width:175px;
        border-color: rgb(255, 102, 0);
    }
    .log-in {
        color:white;
        font-family:'PoppinsMedium', sans-serif;
        width:175px;
    }
    .icon{
        border: none;
    }
    .flag-list-img {
        width: 33px;
    }

    .mobile-container {
        position: relative;
    }

    .default-flag {
        width: 33px;
    }

    .country-list {
        position: absolute;
        background: white;
        border-radius: 3px;
        color: #000;
        top: 40px;
        padding: 0.5em;
        height: 13em;
        overflow: auto;
        z-index: 99;
    }
    .row {
        margin: 0 !important;
    }
    .col-sm-6 {
        display: flex;
        padding: 1em;
        align-items: center;
        cursor: pointer;
    }
    .col-sm-6:hover {
        background: darken(#3468c7, 10%);
    }
    .country-name {
        margin-left: 0.5em;
    }

    span {
        font-size: 0.9em;
    }
    .flag-btn {
        width: 40px;
        border-radius: 3px;
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none !important;
        border-radius: 0;
    }
     h6{
         padding-right: 5px;
    }

    .code {
        background-color:#efefef;
        width:125px;
        font-size:15px;
        
    }
    .danger{
        color:red;
    }

     @media screen and (max-width: 450px){
        .web {
            display: none;
        }
        .mobile{
            display: block;
        }
         .formTop{
            display: block !important;
             flex-direction: column !important;
             flex-wrap: wrap !important;
        }
         .form{
            width: 100%;
        }
         label{
             font-size: 10px;
         }
         .label{
             font-size: 10px;
         }
         .form-control{
             font-size: 10px;
         }
         .form-row{
             font-size: 10px;
         }
         .date-dropdown{
             font-size: 10px;
         }
         h6{
             font-size: 10px;
         }
         .icon{
        border: none;
        }
         .flag-btn {
            width: 20px;
        }
        .ok {
            font-size: 10px;
            align-items: center;
        }
        p{
            font-size: 10px;
        }
        .log-in {
            font-size: 10px;
            align-items: center;
        }

     }

     @media screen and (min-width: 600px){
         .icon{
        border: none;
        }
         .form{
            width: 100%;
        }
        
         label{
             font-size: 20px;
         }
         .label{
             font-size: 20px;
         }
         .form-control{
             font-size: 20px;
         }
         .form-row{
             font-size: 20px;
         }
         .date-dropdown{
             font-size: 20px;
         }
         h6{
             font-size: 20px;
         }
         .flag-btn {
             width: 30px;
        }
        .ok {
             font-size: 20px;
            align-items: center;
        }
        p{
            font-size: 20px;
        }
        .log-in {
            font-size: 20px;
            align-items: center;
        }

     }



</style>