<template>
<div>
  <Header :isAllActive="isAllActive" :showPublicus="showPublicus"></Header>
  <div class="body container-fluid col-12">
    <img src="../../../../assets/assets-01.jpg" alt="" class="bg">
      <div id="content" class="container-fluid col-12 col-sm-12 d-flex align-item-stretch flex-wrap">
        <div class="container text-center">
          <h1 class="text-center text-white mb-3" id="heading">Discover and Vote for your Favorite NFTs</h1>
        </div>
        <div class="lg-screen">
          <div class="card-group d-flex">
            <h3>President</h3>
            <div id="card" class="container-fluid col-sm-3 col-md-6 col-lg-12 col-xl-12 d-flex">
              <prodCard id="prodCard" v-for="nft in nfts.slice(0, 6)" :key="nft.id" :nft="nft"></prodCard>
            </div>
            <div class="d-flex mx-auto">
              <div id="card" class="container-fluid col-sm-3 col-md-6 col-lg-12 col-xl-12 d-flex">
                <prodCard id="prodCard" v-for="nft in nfts.slice(6, 12)" :key="nft.id" :nft="nft"></prodCard>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-screen text-center">
          <h3>President</h3>
          <div class="card-group d-flex">
            <div id="card" class="container-fluid col-sm-3 col-md-6 col-lg-12 col-xl-12 d-flex">
              <prodCard id="prodCard" v-for="nft in nfts" :key="nft.id" :nft="nft"></prodCard>
            </div>
          </div>
          </div>
          <div class="d-flex flex-wrap mt-5">
            <table class="container-fluid text-center chartCon table-responsive" width="100" height="100">
              <canvas id="presChart"></canvas>
            </table>
            <div id="hashTable" style="overflow-y:scroll;">
              <table>
                  <thead>
                    <tr id="heading">
                    <th>Hash</th>
                    <th>Description</th>
                    <th>Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in txnDataP" :key="data.id">
                      <a :href="'https://explorer.cloudchain.it/#/transaction/' + data.txnHash" target="_blank"><td id="hash">{{ data.txnHash | truncate(20)}}</td></a>
                      <td>{{ data.txnDesc }}</td>
                      <td>{{ formatDate(data.txnDate) }}</td> 
                     </tr>
                  </tbody>
                </table> 
            </div>
          </div>
        </div>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import prodCard from '../../../../components/simulation/prodCard.vue'
import Header from '../../../../components/simulation/Header.vue'
import Footer from '../../../../components/Footer.vue'
import Chart from 'chart.js/auto'
import moment from 'moment'
import VprodCard from '../../../../components/simulation/VprodCard.vue'

export default {
  components: {
    prodCard,
    Header,
    Footer,
    VprodCard,
  },

  data() {
    return {
      isAllActive: true,
      txnDataP: [],
      txnDataVP: [],
      Vnfts: [],
      showPublicus: true,
    }
  },

  methods: {
    presChart() {
      const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/candidates/president?type=simulation',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                let candidateArray = [];
                    let voteCountArray = [];
                    response.data.forEach(function(data) {
                        candidateArray.push(data.name)
                        voteCountArray.push(data.voteCount)
                    });
                    
                        let fchart = document.getElementById("presChart").getContext('2d');

                          let fiat = new Chart(fchart, {
                            type:'bar', //line chart
                            data:{
                                labels:candidateArray,
                                datasets: [{
                                    label: 'Votes',
                                    data:voteCountArray,
                                    backgroundColor: [
                                      'rgb(255, 94, 0)',
                                      // 'rgba(255, 159, 64, 0.2)',
                                      // 'rgba(255, 205, 86, 0.2)',
                                      // 'rgba(75, 192, 192, 0.2)',
                                      // 'rgba(54, 162, 235, 0.2)',
                                      // 'rgba(153, 102, 255, 0.2)',
                                      // 'rgba(201, 203, 207, 0.2)'
                                    ],
                                    borderColor: [
                                      'rgb(255, 159, 64)',
                                      // 'rgb(255, 159, 64)',
                                      // 'rgb(255, 205, 86)',
                                      // 'rgb(75, 192, 192)',
                                      // 'rgb(54, 162, 235)',
                                      // 'rgb(153, 102, 255)',
                                      // 'rgb(201, 203, 207)'
                                    ],
                                    borderWidth: 1,
                                }]
                            },

                            options: {
                              maintainAspectRatio: false,
                              tooltips: {
                                displayColors: false,
                                callbacks: {
                                  
                                }
                              }

                                // plugins: {
                                //   tooltip: {
                                //     callback: function (value, index, values) {
                                //       return value + '%'
                                //     }

                                //   }
                                // }
                              
                          }
                          });
            })
            .catch((error) => {

            });
    },

    vpChart() {
      const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/candidates/vpresident',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                let candidateArray = [];
                    let voteCountArray = [];
                    response.data.forEach(function(data) {
                        candidateArray.push(data.name)
                        voteCountArray.push(data.voteCount)
                    });
                    
                        let fchart = document.getElementById("vpChart").getContext('2d');

                          let fiat = new Chart(fchart, {
                            type:'bar', //line chart
                            data:{
                                labels:candidateArray,
                                datasets: [{
                                    label: 'Votes',
                                    data:voteCountArray,
                                    backgroundColor: [
                                      'rgb(255, 94, 0)',
                                      // 'rgba(255, 159, 64, 0.2)',
                                      // 'rgba(255, 205, 86, 0.2)',
                                      // 'rgba(75, 192, 192, 0.2)',
                                      // 'rgba(54, 162, 235, 0.2)',
                                      // 'rgba(153, 102, 255, 0.2)',
                                      // 'rgba(201, 203, 207, 0.2)'
                                    ],
                                    borderColor: [
                                      'rgb(255, 159, 64)',
                                      // 'rgb(255, 159, 64)',
                                      // 'rgb(255, 205, 86)',
                                      // 'rgb(75, 192, 192)',
                                      // 'rgb(54, 162, 235)',
                                      // 'rgb(153, 102, 255)',
                                      // 'rgb(201, 203, 207)'
                                    ],
                                    borderWidth: 1,
                                }]
                            },

                            options: {
                              maintainAspectRatio: false,
                              tooltips: {
                                displayColors: false,
                                callbacks: {
                                  
                                }
                              }

                                // plugins: {
                                //   tooltip: {
                                //     callback: function (value, index, values) {
                                //       return value + '%'
                                //     }

                                //   }
                                // }
                              
                          }
                          });
            })
            .catch((error) => {

            });
    },

    getHashP() {
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/txn/recent/ivote/simulation?position=p',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                this.txnDataP = response.data;
                console.log(this.txnDataP)


                // response.data.forEach((element, index) =>{
                //         this.txnData = element
                //         console.log(this.txnData)
                // });
            })
            .catch((error) => {

            });
    },

    getHashVP() {
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/txn/recent/ivote?position=vp',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                this.txnDataVP = response.data;
                console.log(this.txnDataVP)


                // response.data.forEach((element, index) =>{
                //         this.txnData = element
                //         console.log(this.txnData)
                // });
            })
            .catch((error) => {

            });
    },

    formatDate: function(date) {
           // return date;
            return moment(date).format('MM/DD/YYYY hh:mm:ss A');
        },

    getNfts() {
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/candidates/vpresident',
        };
        this.axios(options)
            .then((response) => {
                this.Vnfts = response.data
            })
            .catch((error) => {

            });
    },
  },
  mounted() {
    this.$store.dispatch("getNFTS");
    this.presChart();
    this.vpChart();
    this.getHashP();
    this.getHashVP();
    this.getNfts();
  },

  computed: {
    nfts(){
      return this.$store.state.nfts
    }
  }
}
</script>

<style scoped>
  .body  {
    background-size:cover;
    background-repeat: no-repeat;
    padding-bottom:50px;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    border:none;
    object-fit: cover;
  }

  #content {
    display: flex;
    justify-content: center;
    flex-direction: row !important;
    padding-top: 40px;
  }
  .chartCon{
      position: relative;
      height: 35vh;
      width: 45vw;
      background-color:black;
      margin-right:10px;
      border-radius:10px;
  }

  #candidateChart {
    background-color:black;
    border-radius:10px;
  }

  #hash {
    white-space: nowrap !important; 
    width: 10px !important; 
    overflow: hidden !important;
    text-overflow: ellipsis !important; 
  }

  #hashTable {
    background-color:black;
    border-radius:10px;
    color:gray;
    font-family:'PoppinsMedium', sans-serif;
    width:45vw;
    padding:20px;
    font-size:12px;
    height:35vh;
    padding-top:0px;
  }

  #hashTable table {
    width:100%;
  }

  #hashTable th {
    font-family:'PoppinsBold', sans-serif;
    font-size:15px;
    color:white;
    padding-bottom:10px;
    position:sticky;
    top:0;
    background-color:black;
    padding-top:10px;
  }

  #hashTable a {
    text-decoration: none;
    color:gray;
  }

   h3 {
    background-color:orange;
    color:white;
    padding:5px;
    border-radius:5px;
  }


  @media screen and (min-width:1920px) {
    .chartCon{
      display: flex;
      height: 30vh;
      width: 45vw;
    }

    #card {
      margin: 0 auto !important;
      width:100%;
      display:flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    #hashTable {
      background-color:black;
      border-radius:10px;
      color:gray;
      font-family:'PoppinsMedium', sans-serif;
      margin:0 auto;
      width:45vw;
      padding:20px;
      padding-top:0px;
      height:30vh;
    }
  }

  
    @media screen and (max-width:1024px) {
    .chartCon{
      display: flex;
      height: 30vh;
      width: 100vw;
    }

    #card {
      margin: 0 auto !important;
      width:100%;
      display:flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    #hashTable {
      background-color:black;
      border-radius:10px;
      color:gray;
      font-family:'PoppinsMedium', sans-serif;
      margin:auto;
      width:80vw;
      padding:10px;
      padding-top:0px;
      height:35vho;

    }
  }

   @media screen and (max-width:800px) {
     .chartCon{
      display: flex;
      height: 30vh;
      width: 100vw;
    }
    #card {
      width:100%;
      display:flex;
      flex-wrap: wrap;
    }

    #hashTable {
      height:35vh;
    }
  }

  @media screen and (min-width:600px) {
     .mb-screen {
       display:none;
     }
  }

  @media screen and (max-width:599px) {
     .lg-screen {
       display:none;
     }
  }

  @media screen and (max-width:440px) {
    #card {
      width:100%;
      display:flex;
      flex-wrap: wrap;
    }

     #hashTable {
      background-color:black;
      border-radius:10px;
      color:gray;
      font-family:'PoppinsMedium', sans-serif;
      margin:auto;
      width:100vw;
      padding:10px;
      font-size:10px;
      height:35vh;
      padding-top:0px;
    }

    #hashTable th {
      font-family:'PoppinsBold', sans-serif;
      font-size:12px;
      color:white;
    }
  }

    @media screen and (max-width:320px) {
    #card {
      width:100%;
      display:flex;
      flex-wrap: wrap;
    }

     #hashTable {
      background-color:black;
      border-radius:10px;
      color:gray;
      font-family:'PoppinsMedium', sans-serif;
      margin:auto;
      width:100vw;
      padding:5px;
      font-size:7px;
      height:28vh;
      padding-top:0px;
    }

    #hashTable th {
      font-family:'PoppinsBold', sans-serif;
      font-size:10px;
      color:white;
    }
    }

 
/*
  #card {
    display:flex;
  }

  #prodCard {
    margin-bottom:100px;
  }

  #heading {
    font-size:50px;
  }
  #candidateChart {
    background-color:black;
    padding:10px;
    border-radius:10px;
  }

  .chartCon {
    width:50%;
    height:auto;
    text-align:center;
  }

  @media screen and (max-width: 1024px) {
        #content {
            padding: 10px;
            margin: 0;
        }

        .btn {
            width:75%;
            margin-top:20px;
        }

        #heading {
          font-size:50px;
        }

        #card {
          margin-left:50px;
        }

        .chartCon {
          width:100%;
          height:auto;
          text-align:center;
        }
    }

    @media screen and (max-width: 768px) {
        #content {
            padding: 10px;
            margin: 0;
        }

        .btn {
            width:75%;
            margin-top:20px;
        }

        #heading {
          font-size:25px;
        }

        #card {
          margin-left:50px;
        }

        .chartCon {
          width:100%;
          height:auto;
          text-align:center;
        }
    }

    @media screen and (max-width: 320px) {
        .btn {
            width:100%;
            margin-top:20px;
        }
    } */
</style>