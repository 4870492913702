<template>
  <div class="backdrop">
      <img src="../../../../assets/assets-01.jpg" alt="" class="bg">
    <div class="b-modal">
      <div class="modalBody modal-dialog-scrollable container justify-content-center align-items-center">
        <form class="form container">
            <div class="labelWrapper">
                    <label for="" class="d-block">Mobile Number</label>
            </div>
            <div class="form-group container justify-content-center formWrapper">
                <div class="flex-wrap d-flex mobileWrapper mt-3 container">
                        <!-- <div class="d-flex flex-wrap">
                            <img :src="selectedMobile.imgPath" id="areaImg"/>
                            <h4 class="mt-1 areaCode">+</h4>
                            <select v-model="areaCode">
                                <option v-for="code in mobileCodes" :key="code.id" :data-thumbnail="code.imgPath" @click="changeFlag">
                                    {{ code.mobileCode }}
                                </option>
                            </select>
                        </div> -->
                        <div class="input-group-prepend code d-flex">
                            <button class="flag-btn" type="button" v-on:click="toggleCountryList">
                                <img class="default-flag" :src="require('../../../../../public/assets/signup_flags/' + country + '.png' )">
                            </button>
                            <h6 class="mt-2" style="background-color:#efefef;">+{{ areaCode }}</h6>
                        </div>
                        <div class="country-list" v-if="showCountry">
                            <div class="row">
                            <div class="col-sm-6" v-for="code in mobileCodes" v-on:click="selectCountry(code)" :key="code.id">
                            <div >
                            <img class="flag-list-img" :src="code.imgPath">
                            </div>

                            <div class="country-name"> <span> {{ code.country }} </span> </div>
                            
                            </div>
                            
                            </div>
                        </div>
                        
                    <div class="form-group-mobile">
                        <input type="text" v-model="mobileNumber" class="textField">
                    </div>
                </div>
            </div>

            <div id="captcha" class="d-flex justify-content-center">
                <div>
                    <img :src="`data:image/png;base64,${captchaString}`" id="captchaImg"/>
                </div>
                <div>
                    <input type="text" class="form-control" v-model="initData.captcha" required>
                </div>
            </div>
            <div class="text-center">
                <p class="text-red">{{ errorMsg }}</p>
            </div>

            <div class="checkboxGroup checkWrapper container-fluid">
                <input type="checkbox" class="form-check-input mr-3" id="agree" v-model="selectedOne" @click="verifySelected()" required>
                <label class="checkLabel form-check-label mt-1" for="agree">I understand that my iVote will be entered into the blockchain.</label>
                <!-- <label class="checkLabel form-check-label" for="agree">will be entered into the blockchain</label> -->
            </div>

            <div class="checkboxGroup checkWrapper container-fluid justify-content-start checkboxGroupTwo">
                <input type="checkbox" class="form-check-input mr-3" id="agree" v-model="selectedTwo" @click="verifySelected()" required>
                <label class="checkLabel form-check-label mt-1" for="agree">I agree to the <a href="https://cloudchain.ai/terms-of-service.html" target="_blank" style="text-decoration:none; color:rgb(255, 102, 0);">Terms of Service</a> and <a href="https://cloudchain.ai/privacy-policy.html" target="_blank" style="text-decoration:none; color:rgb(255, 102, 0);">Privacy Policy</a>.</label>
                <!-- <label class="checkLabel form-check-label" for="agree">will be entered into the blockchain</label> -->
            </div> 
            <div class="text-center">
                <p class="text-red">{{ notSelected }}</p>
            </div>

            <!-- <div class="sendCode justify-content-right">
                <p @click="sendcode">Send Code</p>
            </div> -->

            <div class="buttonWrapper">
                <div class="">
                    <button class="ok mt-3 btn" @click.prevent="sendcode">OK</button>
                </div>
                <div class="">
                    <button @click="closeModal" class="cancel mt-3 btn">CANCEL</button>
                </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import verifyModal from '../pres/verifyModal.vue'
import VueSelectImage from 'vue-select-image'
// add stylesheet
require('vue-select-image/dist/vue-select-image.css')

export default {
    components: {
        VueSelectImage,
        verifyModal
    },
    data: function() {
        return {
            areaCode: '63',
            mobileNumber: '',
            showVerify: false,
            initData: {
                host: 'ivote.cloudchain.it',
                mobileNumber: '',
                captcha: '',
            },
            showVoted: false,
            selectedOne: '',
            selectedTwo: '',
            errorMsg: '',
            notSelected: '',
            captchaString: '',
            selectedMobile: {},
            showCountry: false,
            country: 'PH',
        }
    },

    computed: {
        mobileCodes() {
            return this.$store.state.mobileCodes;
        },

        formData() {
            return this.$store.state.formData;
        }
    },

    mounted() {
      this.$store.dispatch("mobileCodes");
    },

    beforeMount() {
        this.captcha();
        this.changeFlag();
    },

    watch: {
        'signupData.loginId': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.errorMsg = ''
            }
        },
        'signupData.loginPass': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.errorMsg = ''
            }
        },

        'areaCode': function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.mobileNumber = ''
            }
        }
    },

    methods: {
        console() {
            console.log('clicked')
        },

        closeVerify() {
            this.showVerify = false
        },
        closeModal(){
            this.$router.push({ name: 'SProfile', params: { id: this.$route.params.id}})
        },

        verifySelected() {
            if (this.selectedOne == true && this.selectedTwo == true) {
                this.notSelected = ''
            } 
        },

        captcha() {
            const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/vote/captcha',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.captchaString = response.data
                })
                .catch((error) => {
                    // Error code goes here
                    this.errorMsg = error.response.data.msgText
                });
        },

        sendcode() {
            if(this.mobileNumber.length != 0 && this.selectedOne == true && this.selectedTwo == true) {
                // console.log('send code button click')
                const params = {
                    host:   this.initData.host,
                    mobileNumber:this.areaCode + this.mobileNumber,
                    captcha: this.initData.captcha
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data,
                    url: '/cx/vote/init',
                };
                this.axios(options)
                    .then((response) => {
                        // Success Code goes here
                        // console.log(response)
                        // this.showVerify = true
                        this.$router.push({ name: 'snVerifyModal', params: { id: this.$route.params.id}})
                        this.formData.userMobile = this.areaCode + this.mobileNumber
                        localStorage.setItem("usermobile", this.areaCode + this.mobileNumber);

                    })
                    .catch((error) => {
                        // Error code goes here
                        // this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        if(error.response.data.fieldErrors != undefined) {
                            this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        }  else {
                            this.errorMsg = error.response.data.msgText
                        }
                    });
                } else if((this.selectedOne == false) || (this.selectedTwo == false)) {
                    this.notSelected = 'Please agree to all terms and conditions.'
                } 
        },

        refreshChart() {
            this.$parent.generateChart();
            // console.log('refreshing chart')
        },

        register() {
            // console.log(this.otp)
            // console.log(this.mobile)
        },

        // pinBack: function (event, currrentBox) {
        //     if (event.keyCode === 8) {
        //         if (currrentBox != 1) {
        //             setTimeout(function () {
        //                 document.getElementById("pin" + (currrentBox - 1)).focus();
        //             }, 50);
        //          }
        //     }

        //     if (
        //         (event.keyCode >= 48 && event.keyCode <= 57) ||
        //         (event.keyCode >= 96 && event.keyCode <= 105) ||
        //         event.keyCode == 229
        //     ) {
        //         setTimeout(function () {
        //             document.getElementById("pin" + (currrentBox + 1)).focus();
        //         }, 50);
        //     }
        // },
        mobBack: function (event, currrentBox) {
            if (event.keyCode === 8) {
                if (currrentBox != 1) {
                    setTimeout(function () {
                        document.getElementById("mobile" + (currrentBox - 1)).focus();
                    }, 50);
                 }
            }

            if (
                (event.keyCode >= 48 && event.keyCode <= 57) ||
                (event.keyCode >= 96 && event.keyCode <= 105) ||
                event.keyCode == 229
            ) {
                if(currrentBox < 10) {
                    setTimeout(function () {
                        document.getElementById("mobile" + (currrentBox + 1)).focus();
                    }, 50);
                }
            }
            if (event.keyCode === 37) {
                if (currrentBox != 1) {
                    setTimeout(function () {
                        document.getElementById("mobile" + (currrentBox - 1)).focus();
                    }, 50);
                 }
            }
            if (event.keyCode === 39) {
                if(currrentBox < 10) {
                    setTimeout(function () {
                        document.getElementById("mobile" + (currrentBox + 1)).focus();
                    }, 50);
                }
            }
        },


        changeFlag() {
            this.axios
                .get("/assets/mobilecodes.json")
                .then((response) => {
                    let mobileCodes = response.data;
                    mobileCodes.forEach((element, index) => {
                        element.imgPath = '/assets/signup_flags/' + element.countryCode + '.png'
                    if(element.mobileCode == this.areaCode) {
                            this.selectedMobile = element
                            console.log(this.selectedMobile)
                            // console.log(this.imgPath)
                            // console.log('working')
                        }
                    }) 
                    console.log(this.selectedMobile)
                })
                .catch((error) => {
                    // console.log(error);
                });
        },

        selectCountry: function(c) {
            this.showCountry = false;
            this.country = c.countryCode;
            this.areaCode = c.mobileCode
        },
        toggleCountryList: function(){
            this.showCountry = !this.showCountry;
        },
    },
}
</script>

<style scoped>
.bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    border:none;
    object-fit: cover;
  }

.b-modal {
    width: 600px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 2;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    margin-top:200px;
    font-size:25px;
}

.modal-title{
    margin: 0 auto;
    color:black;
    font-family:'PoppinsSemiBold', sans-serif;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:30px;
    border-radius: 10px;
}
/* .form-group-mobile{
    border: 0.3px solid !important;
    border-color: gray !important;
    border-radius: 6px;
    padding: 0px 12px;
} */

.mobileWrapper {
    display: flex;
    justify-content: center;
    width:100%;
    position:relative;
}

.formWrapper, .labelWrapper {
    display: flex;
    justify-content: center;
    width:100%;
}

.checkWrapper {
    display: flex;
    justify-content: center;
    width:100%;
    text-align: center;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    width:100%;
}

.text-right {
  cursor:pointer;
  margin-right:20px;
  color:black;
}

.form {
    width:100%;
    margin: auto auto !important;
}

.textField {
    width:300px;
    padding:5px;
    border:none;
    color:#191919;
    background-color:#efefef;
    border-radius:3px;
    font-family: 'PoppinsRegular', sans-serif;
}

.textField:focus {
    border:none;
    outline:none;
}



label {
    color:black;
    font-family: 'PoppinsMedium', sans-serif;
    font-size:15px;
}

.ok {
    background-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;
    margin-right:20px !important;
}

.cancel {
    color:#191919;
    background-color:lightgray;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;
    margin-right:20px !important;
}

.checkLabel {
    font-size:12px;
    display:inline;
}

.form-check-input {
    background-color:white;
    border-width:2px;
    border-color:lightgray;
    margin-right:5px;
}

input[type=checkbox]:checked {
    background-color:blue;
    border:none;
}

.show {
    color:black;
    font-family:'PoppinsMedium', sans-serif;
    font-size:12px;
    cursor:pointer;
}

.showPass {
    text-align:right;
}

.chooseLogin {
    color:black;
    font-family:'PoppinsMedium', sans-serif;
    font-size:12px;
    cursor:pointer;
}

.text-black {
    color:black;
}

.form-control {
    width:100%;
    height:100%;
    font-size:40px;
}

#footerImg {
  width:25%;
}

#footerText {
  color:black;
  display:inline;
}

#pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
    width:30px;
    padding:5px;
    text-align:center;
    border:none;
    color:#191919;
    background-color:lightgray;
    border-radius:3px;
    font-family: 'PoppinsRegular', sans-serif;
}

.user-country-flag {
  width: 30px;
  height: 20px;
  padding: 3px;
}

.form-group-mobile {
  display: flex;
}

.b-select {
    border-color:gray;
    border-radius:3px;
}

.sendCode {
    font-size:12px;
    margin-top: 5px;
    text-align:right;
    width:350px;
    font-weight:bold;
    cursor:pointer;
}

#pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
    width:50px;
    height:80px;
    padding:5px;
    text-align:center;
    border:none;
    color:#191919;
    background-color:lightgray;
    border-radius:3px;
    font-family: 'PoppinsRegular', sans-serif;
    font-size:30px;
    margin-right:10px;
    display: flex;
}

#mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
    width:25px;
    text-align:center;
    color:#191919;
    background-color:white;
    font-family: 'PoppinsRegular', sans-serif;
    margin:1px;
    border-bottom-color:gray !important;
    border-bottom-width: 0.6px !important;
    border-top:none;
    border-right:none;
    border-left:none;
    font-size:20px;
}

.checkboxGroupTwo {
    margin-left:47px;
}

#areaCode h4 {
    font-size:22px !important;
}

.form-control {
    width:75px;
    height:100%;
    font-size:35px;
}

#areaImg {
    margin-right:2px;
}

#captcha {
    margin-top:10px;
}

.flag-list-img {
      width: 33px;
    }

    .mobile-container {
      position: relative;
    }

      .default-flag {
        width: 33px;
      }

      .country-list {
          position: absolute;
          background: white;
          color: #000;
          top: 40px;
          padding: 0.5em;
          height: 13em;
          overflow: auto;
      }
          .row {
            margin: 0 !important;
          }
            .col-sm-6 {
                display: flex;
              padding: 0.5em;
              align-items: center;
              cursor: pointer;
            }
              .col-sm-6:hover {
                background: darken(#3468c7, 10%);
              }
              .country-name {
                margin-left: 0.5em;
              }

              span {
                font-size: 0.9em;
              }

              .flag-btn {
                color: #47525b;
                border: none;
                outline: none;
                box-shadow: none !important;
                border-radius: 0;
              }

              .code {
                  background-color:#efefef;
              }





    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }

      .textField {
            width:250px;
            padding:5px;
            border:none;
            color:#191919;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }

    .textField {
            width:200px;
            padding:5px;
            border:none;
            color:#191919;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }


            .checkboxGroupTwo {
                margin-left:18px;
            }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;
        }
        .ok {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:20px;
            margin-left:10px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:10px;
        }

        .textField {
            width:160px;
            padding:5px;
            border:none;
            color:#191919;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
            font-size:15px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
            margin-left:50px;
        }

        .checkboxGroupTwo {
            margin-left:15px;
        }

        #areaImg {
            width:25px;
            height:auto;
            object-fit: contain;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 331px;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:18px;
            font-size:14px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkboxGroupTwo {
            margin-left:2px;
        }

        .areaCode {
            font-size:17px;
            margin-top:7px !important;
        }
        .checkLabel {
            font-size:12px;
        }

        #areaImg {
            width:25px;
            height:auto;
            object-fit: contain;
        }

        .form-group-mobile {
            padding:0px;
        }
    }
</style>