import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex, axios, VueAxios);

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'; 
axios.defaults.headers.common['blox-authz'] = localStorage.getItem("tokenId");

export const store = new Vuex.Store({
    state: {
        nfts: [],
        mobileCodes: [],
        formData: {
            userMobile: '',
            userEmail: '',
            txnHash: ''
        }
    },

    actions: {
        getNFTS({ commit }){
            axios.get('/pub/candidates/president')
                .then(response => {
                    commit("SET_NFTS", response.data)
                })
        },

        mobileCodes({ commit }) {
            axios
              .get("/assets/mobilecodes.json")
              .then((response) => {
                let mobileCodes = response.data;
                mobileCodes.forEach((element, index) => {
                    // console.log(element.countryCode)
                    element.imgPath = '/assets/signup_flags/' + element.countryCode + '.png'
                })
                commit("SET_CODES", mobileCodes);
              })
              .catch((error) => {
                // console.log(error);
              });
          },

          mobileNumber({ commit }) {
                commit("SET_CODES", mobileCodes);
          },
    },

    mutations: {
        SET_NFTS(state, nfts) {
            state.nfts = nfts
        },

        SET_CODES(state, mobileCodes) {
            state.mobileCodes = mobileCodes;
          },
    }
})