<template>
  <div class="backdrop">
      <img src="../../../../assets/assets-01.jpg" alt="" class="bg">
    <div class="b-modal">
        
      <div class="modalBody">
        <form class="form container">
            <div class="formWrapper">
                <label for="">Enter your Email Address</label>
            </div>
            <div class="form-group form-group-mobile container justify-content-center formWrapper">
                <div class="flex-wrap d-flex mobileWrapper mt-3 container">
                    <div class="">
                        <input type="email" class="textField" v-model="email">
                    </div>
                </div>
            </div>
            <p class="text-red">{{ errorMsg }}</p>
            <div class="buttonWrapper d-flex flex-wrap justify-content-center">
                <div class="">
                    <button class="ok mt-3 btn" @click.prevent="resendCode">CONFIRM</button>
                </div>
                <div class="">
                    <button @click="closeModal" class="cancel mt-3 btn">CANCEL</button>
                </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    props: ["mobileCode", "mobile"],
    name: "emailModal",
    components: {
      
    },
    data: function() {
        return {
            email: '',
            showVerify: false,
            errorMsg: '',
            initData: {
                host: 'ivote.cloudchain.it',
                mobileNumber: '',
            },
            showResend: false,
            timerInSeconds: 90,
            usermobile: ''
        }
    },

    computed: {
    },

    mounted() {
        console.log(this.mobile)
        this.timer();
        this.getMobile();
    },


    methods: {
        closeModal(){
            this.$router.push({ name: 'optionModal', params: { id: this.$route.params.id}})
        },

        closeVerify() {
            this.showVerify = false
        },

        getMobile() {

       this.usermobile = localStorage.getItem("usermobile")
       /* const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/txn/recent/ivote?rowsPerPage=10',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                response.data.forEach((element, index) =>{
                    console.log(response.data)
                        this.selectedCandidate = element
                        this.hashUrl = 'https://explorer.cloudchain.it/#/transaction/' + element.txnHash
                });
            })
            .catch((error) => {

            });*/
    },

        resendCode() {
            const params = {
                host:   this.initData.host,
                email: this.email,
                mobileNumber: this.usermobile
                // captcha: this.captcha
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/vote/resend/otp',
                };
                this.axios(options)
                    .then((response) => {
                        // Success Code goes here
                        // console.log('success')
                        this.showVerify = true
                        console.log(this.txnHash)
                        console.log('succcess')
                        localStorage.setItem("email", this.email)
                        this.$router.push({ name: 'emailVerify', params: { id: this.$route.params.id}})
                    })
                    .catch((error) => {
                        // Error code goes here

                        console.log(error)
                        if(error.response.data.fieldErrors != undefined) {
                            this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        } else {
                            this.errorMsg = error.response.data.msgText
                        }
                        // console.log("this.errorMsg")
                    });
        },

        sendcode() {
            if(this.email.length != 0) {
                // console.log('send code button click')
                const params = {
                    host:   this.initData.host,
                    email: this.email,
                    mobileNumber: this.usermobile,
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data,
                    url: '/cx/vote/init',
                };
                this.axios(options)
                    .then((response) => {
                        // Success Code goes here
                        // console.log(response)
                        // this.showVerify = true
                        this.$router.push({ name: 'verifyEmail', params: { id: this.$route.params.id}})

                    })
                    .catch((error) => {
                        // Error code goes here
                        // this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        if(error.response.data.fieldErrors != undefined) {
                            this.errorMsg = error.response.data.fieldErrors[0].defaultMessage
                        }  else {
                            this.errorMsg = error.response.data.msgText
                        }
                    });
                } 
        },

        timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0) {
                    clearInterval(counterInterval)
                    this.showResend = true
                    this.timerInSeconds = 180
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        }
    },
}
</script>

<style scoped>
.bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    border:none;
    object-fit: cover;
  }
.b-modal {
    width: 600px;
    height: auto;
    margin: 100px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 2;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    margin-top:200px;
    font-size:25px;
}

.modal-title{
    margin: 0 auto;
    color:black;
    font-family:'PoppinsSemiBold', sans-serif;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;
}

.form {
    width:100%;
    margin: auto auto !important;
}

.textField {
    width:360px;
    padding:5px;
    border:none;
    color:#191919;
    background-color:lightgray;
    border-radius:3px;
    font-family: 'PoppinsRegular', sans-serif;
}

label {
    color:black;
    font-family: 'PoppinsMedium', sans-serif;
    font-size:15px;

}

.ok {
    background-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;
    margin-right:20px !important;
}

.cancel {
    color:#191919;
    background-color:lightgray;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;
    margin-right:20px !important;
}

.buttonWrapper {
    margin-left:25px;
}

.mobileWrapper {
    justify-content: center;
}

    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
     }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
        
  }

    @media screen and (max-width: 600px) {
          
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
            justify-content: center;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 10px;
            border-radius: 10px;
            padding-bottom:50px;
            margin:0 auto;
        }
        .ok {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
        }

        .textField {
            width:245px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin-left:40px;
        }

        .mobileWrapper, .buttonWrapper {
            justify-content: start !important;
        }

        .buttonWrapper {
            margin-left:60px;
        }

        label {
            margin-left:20px;
        }
    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 360px;
        }

        .buttonWrapper {
            margin-left:30px;
        }

        .textField {
            width:250px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin-left:5px;
        }
         label {
            margin-left:0px;
        }
    }

    @media screen and (max-width: 320px) {
        .b-modal {
                width: 315px;
        }

        .textField {
            width:250px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin-left:5px;
            margin-right:10px;
        }
         label {
            margin-left:0px;
            margin-right:5px;
        }
    }
</style>