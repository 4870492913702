<template>
  <div>
      <b-navbar toggleable="xl" class="navbar" type="dark">
        <b-navbar-brand :class="{ 'extended-nav': showPublicus }">
          <router-link to="/"><img src="../assets/assets-06.png" alt="" class="img"></router-link>
          <router-link to="/pia2022"><img src="../assets/publicus-logo.png" alt="" class="publicus-img" v-if="showPublicus"></router-link>
        </b-navbar-brand>
        <b-navbar-toggle target="collapse">
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="chevron-bar-up" class="icon"></b-icon>
            <b-icon v-else icon="chevron-bar-down" class="icon"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="collapse" is-nav>
          <b-navbar-nav class="ml-auto">
                <b-nav-item id="nav-item"><router-link to="/news" class="nav-link">News</router-link></b-nav-item>
                <b-nav-item id="nav-item">
                  <img id="socImg" @click="openFacebook" src="../assets/socmed/socmeds-02.png" alt="">
                  <img id="socImg" @click="openInsta" src="../assets/socmed/socmeds-03.png" alt="">
                  <img id="socImg" @click="openTwitter" src="../assets/socmed/socmeds-01.png" alt="">
                  <img id="socImg" @click="openLinkedIn" src="../assets/socmed/socmeds-04.png" alt="">
                </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
  </div>
</template>

<script>
export default {
    props: ["isAllActive", "isNewsActive", "showPublicus"],

    data: function() {
        return {

        }
    },

    methods: {
        logOut() {
            this.$router.push('/')
        },

        openFacebook() {
          window.open('https://www.facebook.com/iVote.NFT')
        },
        openTwitter() {
          window.open('https://twitter.com/iVote_nft')
        },
        openInsta() {
          window.open('https://www.instagram.com/ivote_ph/')
        },
        openLinkedIn() {
          window.open('https://www.linkedin.com/company/ivote-ph/')
        },
    }
}
</script>

<style scoped>
  .navbar {
    padding:10px;
    background:none;
    color:white !important;
    text-decoration:none !important;
  }

  .navbar-brand {
    width:5%;
  }

  .extended-nav {
    width:20%;
  }

  .logoImg {
    width:100%;
  }

  .nav-link {
    font-size:15px;
    font-family:'PoppinsBold';

    padding:10px;
  }

  .active{
    background-color:orange;
    border-radius:5px;
  }

  .navbar-nav {
    margin-left:20px;
    padding:5px;
  }

  .nav-item {
    margin-left: 10px;
  }

  #dropdown-router{
    text-decoration: none;
    color:black;
  }

  .icon {
    color:white;
    border:none;
    font-size:30px;
  }

  .icon:hover {
    border:none;
  }

  .img {
    width:7rem;
  }

  .publicus-img {
    width:15rem;
  }

  #form-group {
    height:20px !important;
    border-radius: 10px;
    width:200px !important;
  }

  .search {
    border-color:green;
    background:transparent;
    color:green;
  }

  .title{
    color:white;
    margin:0;
    background-color: blue;
    padding:10px;
    width:200px !important;
    border-radius:5px;
  }

  #socImg {
      width:40px;
      margin:2px;
  }

    @media screen and (max-width: 420px) {
        .navbar, .navbar-nav, .nav-item {
            padding: 10px;
            margin: 0;
        }

        .img {
          width:6rem;
        }

        .publicus-img {
          width:12rem;
        }
    }

    @media screen and (max-width: 320px) {
        .btn {
            width:100%;
            margin-top:20px;
        }

        .img {
          width:4rem;
        }

        .publicus-img {
          width:10rem;
        }
    }
</style>