<template>
  <div class="backdrop">
      <img src="../../../../assets/assets-01.jpg" alt="" class="bg">
    <div class="b-modal text-center">
      <div class="modalBody container-fluid">
          <div class="text container-fluid">
              <h5>Your NFT vote has been accepted. Please visit us again to monitor results and for future NFT voting.</h5>
              <h6>Press OK to see the updated number of votes.</h6>
              <div class="txnHash text-center">
                  <p>Here is your proof of vote:  </p>
                  <a v-bind:href="'https://explorer.cloudchain.it/#/transaction/'+ txnhash" target="_blank"><button class="text-center btn hashButton"> https://explorer.cloudchain.it/#/transaction/{{txnHash}}</button></a>
              </div>
          </div>
        <button class="submit btn" @click="done">OK</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  components: {

  },
   props: ["txnHash"],
  data() {
      return {
          selectedCandidate:{},
          //txnHash:{},
          hashUrl: '',
          txnhash: ''
      }
  },

  mounted() {
      this.getHash();
  },

  computed: {
      formData() {
          return this.$store.state.formData;
      }
  },
  
    methods: {
        done() {
            this.$router.push('/presidential-election/confirm/' + this.$route.params.id) 
        },

        getCandidates() {
        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/candidates/president',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                response.data.forEach((element, index) =>{
                    if(element.entityId == this.$route.params.id) {
                        this.selectedCandidate = element
                    }
                });
            })
            .catch((error) => {

            });
    },
    getHash() {

        this.txnhash = localStorage.getItem("txnhash")
       /* const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/pub/txn/recent/ivote?rowsPerPage=10',
        };
        this.axios(options)
            .then((response) => {
                // Success Code goes here
                response.data.forEach((element, index) =>{
                    console.log(response.data)
                        this.selectedCandidate = element
                        this.hashUrl = 'https://explorer.cloudchain.it/#/transaction/' + element.txnHash
                });
            })
            .catch((error) => {

            });*/
    }
    },

    mounted() {
        this.getCandidates();
        this.getHash();
    }
}
</script>

<style scoped>
.bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    border:none;
    object-fit: cover;
  }
.b-modal {
    width: 620px;
    height: auto;
    margin: 175px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 2;
    overflow-x: hidden !important;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    margin-top:200px;
    font-size:25px;
}

.modal-title{
    margin: 0 auto;
    color:black;
    font-family:'PoppinsSemiBold', sans-serif;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:20px;
    border-radius: 10px;
}

.submit, .cancel {
    background-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:200px;
    margin-right:10px;
}

.hashButton {
    border-width:2px;
    border-color:black;
    margin-bottom:10px;
    white-space: nowrap; 
    width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.hashButton:hover {
    background-color:black;
    color:white;
}
@media screen and (max-width: 600px) {
        .txnHash {
            margin-left:5px;
        }
    }

@media screen and (max-width: 420px) {
        .b-modal {
            width: 420px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
            overflow: auto;
            top:0;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px;
            border-radius: 10px;
            font-size:15px;
        }
        .submit, .cancel {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            text-align:center;
            margin: 0 auto;
            margin-left:20px;
        }

        .text {
            font-size:15px;
            width:300px;
            text-align:center;
            margin-right:20px;
        }

        .hashButton {
            margin-right:40px;
        }
    }

    @media screen and (max-width: 380px) {
        .b-modal {
            width: 380px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 10px;
            border-radius: 10px;
        }
        .submit, .cancel {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-left:20px;
        }

        .txnHash {
            margin-left:35px;
        }
    }

    @media screen and (max-width: 320px) {
        .b-modal {
            width: 320px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px;
            border-radius: 10px;
        }
        .submit, .cancel {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-left:20px;
        }

        .txnHash {
            margin-left:35px;
        }
    }
</style>